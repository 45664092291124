/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 7/3/2020
 * @Example
 */
import React from "react";
import { connect } from "react-redux";
import * as w29F2025Actions from "../../../../redux/W2X/W29F2025/W29F2025_actions";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import {Column, RequiredRule, CustomRule} from 'devextreme-react/data-grid';
import {Row, Col, FormGroup} from "react-bootstrap"
import GridContainer from "../../../grid-container/grid-container";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import ButtonGeneral from "../../../common/button/button-general";
import withStyles from "@material-ui/core/styles/withStyles";
import * as generalActions from "../../../../redux/general/general_actions";
import {Checkbox, Combo, TextField} from "../../../common/form-material";
import Filter from '../../../filter/filter';
import DropDownOrganization from "../../../common/dropdown/dropdown-treeview";
import UserImage from "../../../common/user/user-image";
import { TextBox } from 'devextreme-react/text-box';
import UserName from "../../../common/user/user-name";
import HeadClick from "../../../grid-container/head-click";
import ButtonCustom from "../../../common/button/button-custom";
import moment from "moment";
import { Lookup } from "devextreme-react";
const styles = {
    boxShift: {
        width: "80px",
        height: "36px",
        lineHeight: "40px",
        textAlign: "center",
        background: "transparent",
        margin: "0 5px",
    },
    boxTextShift: {
        width: "80px",
        height: "36px",
        lineHeight: "40px",
        textAlign: "center",
        background: "transparent",
        margin: "0 5px",
        borderRight: "1px solid #000"
    },
    textBox: {
        maxWidth: 120,
        "& input": {
            padding: "16px 12px 12px !important"
        }
    },
    divError : {
        textAlign: "right",
        color: "red",
        fontSize: 25,
    }
};
class W29F2025 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            dataCboProjects: {
                rows: [],
                total: 0
            },
            dataGrid: [],
            isChecked: false,
            loadingCboProjects: false,
            gridLoading: false,
            StrEmployeeID: "",
            datafilter: [],
            dataDropDown: [],
            ShiftID: "",
            dataShiftRotationTypeID: "",
            allowEditing: false,
            isUpdated: false,
            filterGridValue: [],
            isShiftRotation: false,
            isCancel: false,
            isColumnDefault: false,
        };
        this.filter = {
            EmployeeID: "",
            OrgChartID: "",
            ProjectID: "",
        };
        this.filterCboProject = {
            strSearch: "",
            skip: 0,
            limit: 20
        };
        this.filterGrid = {};
        this.selectedRange = {};
        this.tmpClipboard = {
            numCols: 0,
            numRows: 0,
            values: []
        };
        this.dataGrid = null;
        this.isUpdated = false;
        this.isSave = false;
        this.rowIndex = '';
        this.dataInit = "";
        this.filterColIndx = -1;
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W29F2025", (isPer) => {
            this.setState({ iPermission: isPer });
        });
    };

    loadListOfShift = () => {
        const { ProjectID, OrgChartID } = this.filter;
            const param = {
                DivisionID: Config.getDivisionID(),
                OrgChartID: OrgChartID ? OrgChartID : "",
                WorkDayType: "",
                AfterOTHours: 0,
                Language: Config.language || 84,
                ProjectID: ProjectID ? ProjectID.ProjectID : ""
            };
            this.props.w29F2025Actions.getListOfShift(param, error => {
                if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            });
     };

    loadListShiftRotation = () => {
        const param = {
            DivisionID: Config.getDivisionID(),
        };
        this.setState({ gridLoading: true });
        this.props.w29F2025Actions.getListShiftRotation(param, error => {
            this.setState({ gridLoading: false });
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
        });
    };

    loadDropDown = () => {
        this.setState({ gridLoading: true });
        this.props.w29F2025Actions.getLoadDropDown((error, data) => {
            this.setState({ gridLoading: false });
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
            if(data) {
                this.setState({dataDropDown: data});
            }
        });
    };


    loadCboProjects = (isReset)=>{
        const param = {
            FormID : "W29F2025",
            skip: this.filterCboProject.skip,
            limit: this.filterCboProject.limit,
            search: this.filterCboProject.strSearch
        };
        this.setState({loadingCboProjects : true});
        this.props.generalActions.getCboProjects(param, (error, data) => {
            this.setState({loadingCboProjects : false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const {dataCboProjects} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboProjects: {
                        rows: isReset ? rows : dataCboProjects.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    loadFormGrid = (isChecked) => {
        const { OrgChartID, ProjectID } = this.filter;
        const { StrEmployeeID } = this.state;
        let params = {
            DivisionID: Config.getDivisionID(),
            OrgChartID: OrgChartID ? OrgChartID : "",
            ProjectID: ProjectID ? ProjectID.ProjectID : "",
            FormID: "W29F2025",
            Language: Config.language || "84",
            StrEmployeeID: StrEmployeeID ? StrEmployeeID : "",
            IsShift: isChecked ? 1 : 0
        };
        this.loadListOfShift();
        this.setState({ gridLoading: true });
        this.props.w29F2025Actions.getLoadFormGrid(params, (errors, data) => {
            if (errors) {
                let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('ERROR', message);
                this.setState({ gridLoading: false });
                return false;
            }
            if(data) {
                this.dataInit = JSON.parse(JSON.stringify(data));
                this.setState({
                    dataGrid: data,
                }, () => {
                    this.setState({ gridLoading: false });
                });
            }
        });
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (!this.state.iPermission) return;
        this.loadListOfShift();
        this.loadFormGrid();
    };

    onLoadDataFilter = () => {
        this.loadCboProjects();
    };

    filterChange = (key, e) => {
        if (!key) return false;
        switch (key) {
            case "isChecked":
                const checked = e.target.checked;
                const {isUpdated} = this.state;
                if (isUpdated) {
                    Config.popup.show("YES_NO", Config.lang("DHR_Co_du_lieu_da_duoc_sua_Ban_co_muon_tiep_tuc?"), () => {
                        this.loadFormGrid(checked);
                        this.setState({isChecked: checked});
                    });
                } else {
                    this.loadFormGrid(checked);
                    this.setState({isChecked: checked});
                }
                break;
            case "OrgChartID":
                this.filter.OrgChartID = (e && e[0]) || "";
                break;
            case "ProjectID":
                this.filter.ProjectID = e.value;
                break;
            case "StrEmployeeID":
                 this.setState({StrEmployeeID : e.target.value});
                 break;
            case "ShiftID":
                 this.setState({ShiftID : e.value});
                 break;
            case "ShiftRotationTypeID":
                 this.setState({dataShiftRotationTypeID : e.value});
                 break;
            default:
                break;
        }
    };

    renderFilters = () => {
        const { dataCboProjects, loadingCboProjects, StrEmployeeID } = this.state;
        const { OrgChartID, ProjectID } = this.filter;

        return (
            <Filter
                placeholder={Config.lang("DHR_Tim_kiem_ho_so_nhan_su")}
                onOpenLoaded={this.onLoadDataFilter}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <DropDownOrganization
                                            label={Config.lang("DHR_Chon_co_cau_to_chuc")}
                                            showClearButton={true}
                                            value={OrgChartID}
                                            typeDefault={"organizational"}
                                            onValueChanged={(e, data) => this.filterChange('OrgChartID', data)}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo.LoadMore
                                            dataSource={dataCboProjects}
                                            skip={this.filterCboProject.skip}
                                            limit={this.filterCboProject.limit}
                                            displayExpr={'ProjectName'}
                                            keyExpr={'ProjectID'}
                                            valueExpr={'ProjectID'}
                                            value={ProjectID}
                                            loading={loadingCboProjects}
                                            stylingMode={'outlined'}
                                            label={Config.lang('DHR_Du_an')}
                                            itemRender={(e) => {
                                                const {data} = e;
                                                if (!data) return null;
                                                return data.ProjectID + ' - ' + data.ProjectName;
                                            }}
                                            showClearButton={true}
                                            // showCheckBoxMode={"none"}
                                            onValueChanged={e => this.filterChange("ProjectID", e)}
                                            onInputChanged={(e) => {
                                                this.filterCboProject.strSearch = e.target.value;
                                                this.filterCboProject.skip = 0;
                                                this.loadCboProjects(true);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboProject.skip = e.skip;
                                                this.filterCboProject.limit = e.limit;
                                                this.loadCboProjects();
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <TextField
                                            label={Config.lang('DHR_Nhan_vien')}
                                            variant={"outlined"}
                                            value={StrEmployeeID}
                                            onChange={(e) => this.filterChange("StrEmployeeID", e)}
                                            fullWidth
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={"display_row align-center valign-middle"}>
                                <ButtonGeneral
                                    name={Config.lang("DHR_Tim_kiem")}
                                    typeButton={"search"}
                                    size={"large"}
                                    color={"primary"}
                                    variant={"outlined"}
                                    onClick={this.loadFormGrid}
                                />
                            </FormGroup>
                        </>
                    );
                }}
            />
        );
    };

    renderNameEmployee = e => {
        const { data } = e.row;
        const user = Config.getUser({EmployeeID: data.EmployeeID});
        data.EmployeeName = data && data.EmployeeName ? data.EmployeeName : (user && user.EmployeeName ? user.EmployeeName : "");
        return <UserName data={data} />
    };

    onDisable = (mode) => {
        let disable = false;
        if(mode === "Rotation") {
            disable = true;
            this.loadListShiftRotation();
            this.loadDropDown();
        }
        this.setState({ allowEditing : true, gridLoading: true, isShiftRotation: disable, isCancel: true, isColumnDefault: true}, () => {
            setTimeout(() => this.setState({gridLoading: false}), 300);
        });
    };

    onSave = () => {
        // const { classes } = this.props;
        const { isShiftRotation } = this.state;
        if (this.dataGrid) {
            this.dataGrid.instance.saveEditData();
            const editDataUnSave = this.dataGrid.instance.hasEditData();
            if(editDataUnSave) {
                return false;
            }
            const dataSource = this.dataGrid.instance.option("dataSource");
            const dataUpdated = dataSource && dataSource.filter(data => data && data.IsUpdated === 1);
            if (dataUpdated && dataUpdated.length > 0) {
                let checkDataGrid = isShiftRotation && dataUpdated.find((data => data.ValidRotateDate === null && data.ShiftName === "" && data.ShiftID === "" && data.ShiftRotationTypeID === ""));
                if(!checkDataGrid){
                    if(isShiftRotation) {
                        const ValidRotateDate = dataUpdated.find((data => data.ValidRotateDate === null));
                        const RotateOrder = dataUpdated.find(data => (data.RotateOrder === 0 || data.RotateOrder === "")
                            && data.ValidRotateDate && data.ShiftRotationTypeID );
                        // const dataIsNotShiftRotationTypeID = dataUpdated.find(data => !data.ShiftRotationTypeID);
                        // if(dataIsNotShiftRotationTypeID) {
                        //     setTimeout(() => {
                        //         this.dataGrid.instance.navigateToRow(dataIsNotShiftRotationTypeID.EmployeeID);
                        //         Config.popup.show('INFO', Config.lang("DHR_Ban_phai_gan_mau_ca"), null, null, () => {
                        //             setTimeout(() => {
                        //                 const rowIndx = this.dataGrid.instance.getRowIndexByKey(dataIsNotShiftRotationTypeID.EmployeeID);
                        //                 const cellElement = this.dataGrid.instance.getCellElement(rowIndx, "ShiftRotationTypeID");
                        //                 cellElement.style.backgroundColor = "rgba(244,67,54,.32)";
                        //                 cellElement.innerHTML = `<div class=${classes.divError}><i class="fal fa-exclamation-circle"></i></div>`;
                        //             }, 400);
                        //         });
                        //     }, 500);
                        //     return false;
                        // }
                        if(RotateOrder) {
                            setTimeout(() => {
                                this.dataGrid.instance.navigateToRow(RotateOrder.EmployeeID);
                                Config.popup.show('INFO', Config.lang("DHR_Ban_chua_nhap_so_thu_tu"), null, null, () => {
                                    setTimeout(() => {
                                        const rowIndx = this.dataGrid.instance.getRowIndexByKey(RotateOrder.EmployeeID);
                                        this.dataGrid.instance.editCell(rowIndx, "RotateOrder");
                                    }, 200);
                                });
                            }, 200);
                        }

                        if(ValidRotateDate) {
                            setTimeout(() => {
                                this.dataGrid.instance.navigateToRow(ValidRotateDate.EmployeeID);
                                Config.popup.show('INFO', Config.lang("DHR_Ban_chua_nhap_ngay_di_ca"), null, null, () => {
                                    setTimeout(() => {
                                        const rowIndx = this.dataGrid.instance.getRowIndexByKey(ValidRotateDate.EmployeeID);
                                        this.dataGrid.instance.editCell(rowIndx, "ValidRotateDate");
                                    }, 200);
                                });
                            }, 200);

                        }
                    }
                }
                // const dataIsNotDefaultShiftID = dataUpdated.find(data => !data.DefaultShiftID);
                // if(dataIsNotDefaultShiftID && !isShiftRotation) {
                //     setTimeout(() => {
                //         this.dataGrid.instance.navigateToRow(dataIsNotDefaultShiftID.EmployeeID);
                //         Config.popup.show('INFO', Config.lang("DHR_Ban_phai_gan_ca_mac_dinh"), null, null, () => {
                //             setTimeout(() => {
                //                 const rowIndx = this.dataGrid.instance.getRowIndexByKey(dataIsNotDefaultShiftID.EmployeeID);
                //                 const cellElement = this.dataGrid.instance.getCellElement(rowIndx, "DefaultShiftID");
                //                 cellElement.style.backgroundColor = "rgba(244,67,54,.32)";
                //                 cellElement.innerHTML = `<div class=${classes.divError}><i class="fal fa-exclamation-circle"></i></div>`;
                //             }, 400);
                //         });
                //     }, 500);
                //     return false;
                // }

                const dataIsNotValidDate = dataUpdated.find(data => !data.ValidDate);
                if (dataIsNotValidDate && !isShiftRotation) {
                    const {filterGridValue} = this.state;
                    if (filterGridValue && filterGridValue.length > 0) {
                        this.setState({filterGridValue: this.getFilterGrid(true)}, () => {
                            setTimeout(() => {
                                this.dataGrid.instance.navigateToRow(dataIsNotValidDate.EmployeeID);
                                Config.popup.show('INFO', Config.lang("DHR_Ban_phai_nhap_ngay_hieu_luc"), null, null, () => {
                                    setTimeout(() => {
                                        const rowIndx = this.dataGrid.instance.getRowIndexByKey(dataIsNotValidDate.EmployeeID);
                                        this.dataGrid.instance.editCell(rowIndx, "ValidDate");
                                    }, 400);
                                });
                            }, 500);
                        });
                    } else {
                        this.dataGrid.instance.navigateToRow(dataIsNotValidDate.EmployeeID);
                        Config.popup.show('INFO', Config.lang("DHR_Ban_phai_nhap_ngay_hieu_luc"), null, null, () => {
                            setTimeout(() => {
                                const rowIndx = this.dataGrid.instance.getRowIndexByKey(dataIsNotValidDate.EmployeeID);
                                this.dataGrid.instance.editCell(rowIndx, "ValidDate");
                            }, 400);
                        });
                    }
                    return false;
                } else {
                    Config.popup.show('YES_NO', Config.lang("DHR_Ban_co_muon_cap_nhat_lai_lich_di_ca_theo_ngay_hieu_luc?"), () => { //Yes
                            this._saveData(dataUpdated, 1);
                    }, () => { //No
                            this._saveData(dataUpdated, 0);
                    });
                }
            }
        } else {
            Config.popup.show('ERROR', Config.lang("DHR_Loi_chua_xac_dinh"));
            return false;
        }
    };

    _saveData = (dataUpdated, isScheduled = 0) => {
        if (this.dataGrid) {
            if (dataUpdated && dataUpdated.length > 0) {
                dataUpdated = dataUpdated.map(data => {
                    return {...data,
                        ValidDate: data && data.ValidDate ? moment(data.ValidDate).format("YYYY-MM-DD HH:mm:ss") : null,
                        ValidRotateDate:data && data.ValidRotateDate ? moment(data.ValidRotateDate).format("YYYY-MM-DD HH:mm:ss") : null}
                });
                const params = {
                    DivisionID : Config.getDivisionID(),
                    TranMonth: Config.getHRTransMonth(),
                    TranYear: Config.getHRTransYear(),
                    Language: Config.language || '84',
                    IsSchedule: isScheduled,
                    data: JSON.stringify(dataUpdated)
                };
                this.setState({dataLoading: true});
                this.props.w29F2025Actions.save(params, (error, data) => {
                    this.setState({dataLoading: false});
                    if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
                    if (data) {
                        if (data.Status === 0) {
                            Config.notify.show("success", Config.lang("DHR_Luu_thanh_cong"), 2000);
                            this.setState({isChecked: false, isUpdated: false}, () => {
                                this.loadFormGrid();
                            });
                        } else {
                            Config.popup.show("INFO", data.Message);
                            return false;
                        }
                    }
                });
            }
        }
    };

    renderEmployeeID = (e) => {
        const {data} = e.row;

        return (
            <div className={"display_row align-center flex-wrap"}>
                <UserImage data={data} width={42} height={42}/>
                {data.EmployeeID}
            </div>
        );
    };

    fieldRender = (e) => {
        if (e && e.ShiftID) {
            const {classes} = this.props;
            let name = e ? e.TimeStart + " - " + e.TimeEnd : "";
            return (
                <div className={"display_row align-center"}>
               <span className={classes.boxShift}
                     style={{backgroundColor: this.getShiftColor(e)}}>{e.ShiftID || ""}</span>
                    <TextBox
                        className={classes.textBox}
                        defaultValue={name || ""}
                        readOnly={true}
                    />
                </div>
            )
        } else {
            return <TextBox defaultValue={""}
                readOnly={true}
            />
        }
    };

    renderHeaderColumns = (e) => {
        if (!e) return false;
        // return <div style={{color: col.HeaderTextColor}}>{col.Caption}</div>
        return <HeadClick selectedRange={this.selectedRange}
                          dataHeaderCell={e}
                          allowClick={true}
                          callbackAfterCopy={e => this.setState({isUpdated: e.IsUpdated})}
        />
    };

    onKeyDown = (e) => {
        const {event} = e;
        // const {rowIndx, colIndx, column} = this.postionFocused;
        const {startColumnIndex, startRowIndex, endColumnIndex, endRowIndex} = this.selectedRange;
        if (event && event.ctrlKey) {
            if (event.keyCode === 67) { //ctrl + C
                const columns = e.component.getVisibleColumns();
                let data = [];
                for(let rowIndx = startRowIndex; rowIndx <= endRowIndex; rowIndx++) {
                    let dataRow = [];
                    for(let colIndx = startColumnIndex; colIndx <= endColumnIndex; colIndx++) {
                        const column = columns[colIndx];
                        if (column && column.allowCopying) {
                            const dataField = column.dataField;
                            dataRow.push({[dataField]: e.component.cellValue(rowIndx, colIndx)});
                        }
                    }
                    data.push(dataRow)
                }
                this.tmpClipboard = {
                    numCols: endColumnIndex - startColumnIndex,
                    numRows: endRowIndex - startRowIndex,
                    values: data
                };
            } else if (event.keyCode === 86) { //ctrl + v
                if (this.tmpClipboard && this.tmpClipboard.values.length > 0) {
                    const colMaxTmp = endColumnIndex - startColumnIndex;
                    const rowMaxTmp = endRowIndex - startRowIndex;
                    const colIndxTmp = colMaxTmp > this.tmpClipboard.numCols ? colMaxTmp : this.tmpClipboard.numCols;
                    const rowIndxTmp = rowMaxTmp > this.tmpClipboard.numRows ? rowMaxTmp : this.tmpClipboard.numRows;
                    const values = this.tmpClipboard.values;
                    const columns = e.component.getVisibleColumns();

                    let iRow = 0, iCol = 0;
                    for(let rowIndx = startRowIndex; rowIndx <= (startRowIndex + rowIndxTmp); rowIndx++) {
                        iCol = 0;
                        for(let colIndx = startColumnIndex; colIndx <= (startColumnIndex + colIndxTmp); colIndx++) {
                            const column = columns[colIndx];
                            if (column && column.allowPasting) {
                                const value = values[iRow][iCol];
                                const dataField = column.dataField;
                                if (value[dataField]) {
                                    e.component.cellValue(rowIndx, colIndx, value[dataField]);
                                }
                            }
                            iCol = iCol < this.tmpClipboard.numCols ? iCol + 1 : 0;
                        }
                        iRow = iRow < this.tmpClipboard.numRows ? iRow + 1 : 0;
                    }
                    e.component.saveEditData();
                }
            }
        } else if (event && event.keyCode === 46) {
            for(let rowIndx = startRowIndex; rowIndx <= endRowIndex; rowIndx++) {
                for(let colIndx = startColumnIndex; colIndx <= endColumnIndex; colIndx++) {
                    const columns = e.component.getVisibleColumns();
                    let dataAllowDelete = ["DefaultShiftID","ValidRotateDate","ValidDate","ShiftRotationTypeID"];
                    let dataRow = [];
                    for(let rowIndx = startRowIndex; rowIndx <= endRowIndex; rowIndx++) {
                        for(let colIndx = startColumnIndex; colIndx <= endColumnIndex; colIndx++) {
                            const column = columns[colIndx];
                            if (column && column.allowEditing) {
                                const dataField = column.dataField;
                                dataRow.push(dataField);
                            }
                        }
                    }
                    let checkAllowDelete =  dataAllowDelete.some(val => dataRow.includes(val));
                    if(checkAllowDelete) {
                        if(dataRow && dataRow[0] === "ValidRotateDate") {
                            e.component.cellValue(rowIndx, dataRow[0], null);
                        } else {
                            e.component.cellValue(rowIndx, colIndx, "");
                        }

                        e.component.saveEditData();
                    }
                }
            }
        }
    };

    getFilterGrid = (isClear) => {
        if (isClear) this.filterGrid = {};
        const filter = this.filterGrid;
        const dataFilter = [];
        Object.keys(filter).forEach(fil => {
            const itemFilter = filter[fil];
            if (itemFilter && itemFilter.length > 0) {
                dataFilter.push(filter[fil]);
                dataFilter.push("and");
            }
        });
        dataFilter.pop();
        return dataFilter;
    };

    onEditingStart = (e) => {
        if (!e) return false;
        const key = e.component.getRowIndexByKey(e.key);
        this.rowIndex = key;
        const {column} = e;
        if ((column && column.dataField === "DefaultShiftID") || (column && column.dataField === "ShiftRotationTypeID")) {
            e.cancel = true;
        }
    };

    getShiftColor = (shift) => {
        if (shift && shift.ShiftColor) {
            return shift.ShiftColor.indexOf("#") > -1 ? shift.ShiftColor : "#" + shift.ShiftColor;
        }
        return "";
    };

    cellRender = (e) => {
        const {getListOfShift} = this.props;
        const {data, column, rowIndex} = e;
        if (column && column.dataField.indexOf("ShiftID") > -1) {
            const cellElement = e.component.getCellElement(rowIndex, column.dataField);
            if (data && cellElement) {
                const shift = getListOfShift.find(s => {
                    return s.ShiftID === data[column.dataField]
                });
                let bgColor = "";
                if (shift && shift.ShiftColor) {
                    bgColor = shift.ShiftColor.indexOf("#") > -1 ? shift.ShiftColor : "#" + shift.ShiftColor;
                }
                cellElement.style.backgroundColor = bgColor;
            }
        }
        return e.value;
    };

    cellRenderRotateOrder = (e) => {
        if(!e) return false;
        return e.value;
    };

    setShift = () => {
        const {ShiftID} = this.state;
        if (this.dataGrid && this.selectedRange && ShiftID) {
            let isUpdated = false;
            const columns = this.dataGrid.instance.getVisibleColumns();
            const {startColumnIndex, startRowIndex, endColumnIndex, endRowIndex} = this.selectedRange;
            for(let rowIndx = startRowIndex; rowIndx <= endRowIndex; rowIndx++) {
                for(let colIndx = startColumnIndex; colIndx <= endColumnIndex; colIndx++) {
                    const column = columns[colIndx];
                    if (column && column.dataField === "DefaultShiftID") {
                        this.dataGrid.instance.cellValue(rowIndx, colIndx, ShiftID);
                        isUpdated = true;
                    }
                }
            }
            if (isUpdated) this.setState({isUpdated: true});
            this.dataGrid.instance.saveEditData();
            this.dataGrid.instance.refresh();
        }
    };

    setShiftRotation = () => {
        const {dataShiftRotationTypeID} = this.state;
        if (this.dataGrid && this.selectedRange && dataShiftRotationTypeID.ShiftRotationTypeID) {
            let isUpdated = false;
            const columns = this.dataGrid.instance.getVisibleColumns();
            const {startColumnIndex, startRowIndex, endColumnIndex, endRowIndex} = this.selectedRange;
            for(let rowIndx = startRowIndex; rowIndx <= endRowIndex; rowIndx++) {
                for(let colIndx = startColumnIndex; colIndx <= endColumnIndex; colIndx++) {
                    const column = columns[colIndx];
                    if (column && column.dataField === "ShiftRotationTypeID") {
                        this.dataGrid.instance.cellValue(rowIndx, colIndx, dataShiftRotationTypeID.ShiftRotationTypeID);
                        isUpdated = true;
                    }
                }
            }
            if (isUpdated) this.setState({isUpdated: true});
            this.dataGrid.instance.saveEditData();
            this.dataGrid.instance.refresh();
        }
    };

    onRowUpdating = (e) => {
        if(!e) return;
        const data = e.newData ? e.newData : null;
        const oldData = e.oldData ? e.oldData : null;
        if(data.hasOwnProperty("ValidRotateDate")) {
            data.RotateOrder = "";
            data.ShiftName = "";
            data.ShiftID = "";
            data.ValidDate = data.ValidRotateDate ? data.ValidRotateDate : oldData.ValidDate;
        }
        if(data.hasOwnProperty("ShiftRotationTypeID")) {
            data.RotateOrder = "";
            data.ShiftName = "";
            data.ShiftID = "";
            data.ValidRotateDate = null;
        }
    };

    onRowUpdated = (e) => {
        if(!e) return;
        let {DefaultShiftID, ShiftRotationTypeID } = e.data;
        const { isShiftRotation } = this.state;
        e.data.IsUpdated = 1;
        if(isShiftRotation) {
            if(DefaultShiftID) {
                e.data.DefaultShiftID = ""
            }
        }
        else {
            if(ShiftRotationTypeID) {
                e.data.ShiftRotationTypeID = "";
                e.data.RotateShiftID = "";
                e.data.RotateMode = 0;
                e.data.ValidRotateDate = null;
            }
        }
        if (!this.state.isUpdated) this.setState({isUpdated: true});
    };

    onBack = () => {
        this.dataGrid.instance.cancelEditData();
        this.filterGrid = {};
        this.setState({dataGrid: this.dataInit, allowEditing: false, isShiftRotation: false,
            isCancel: false, isUpdated: false,isChecked: false, filterGridValue: [],isColumnDefault: false}, () => {
            this.loadFormGrid();
        });
    };

    setFocusFilter = (timeout = 1000) => {
        setTimeout(() => {
            if (this.filterColIndx > -1) {
                let containerFilter = document.getElementsByClassName("dx-datagrid-filter-row");
                containerFilter     = containerFilter && containerFilter.length > 0 ? containerFilter[0] : null;
                if (containerFilter) {
                    const cellFilter = containerFilter.querySelector("td:nth-child(" + (this.filterColIndx + 1) + ")");
                    // e.component.focus(cellFilter.getElementsByTagName("input")[0])
                    if (cellFilter) cellFilter.getElementsByTagName("input")[0].focus();
                }
                this.filterColIndx = -1;
            }
        }, timeout);
    };

    cellRenderShiftRotation = (e) => {
        // const { classes} = this.props;
        // const {rowIndex} = e;
        // const cellElement = e.component.getCellElement(rowIndex, "ShiftRotationTypeID");
        // if(cellElement && e.data.ShiftRotationTypeID === "" && e.data.IsUpdated === 1) {
        //     cellElement.style.backgroundColor = "rgba(244,67,54,.32)";
        //     cellElement.innerHTML = `<div class=${classes.divError}><i class="fal fa-exclamation-circle"></i></div>`;
        // }
        return e.value;
    };

    // cellRenderRotateDate = (e) => {
    //     if(!e) return false;
    //     let { ValidDate,ValidRotateDate, IsUpdated, ShiftRotationTypeID  } = e.data;
    //     // e.component.cellValue(e.rowIndex, "RotateDate")
    //     let dataTime = (IsUpdated && e.value === null) ? null  :
    //         (ValidRotateDate ? moment(ValidRotateDate).format("DD/MM/YYYY") : moment(ValidDate).format("DD/MM/YYYY"))
    //
    //     return  (IsUpdated && e.value === null) ? null  :
    //         (ValidRotateDate ? moment(ValidRotateDate).format("DD/MM/YYYY") : moment(ValidDate).format("DD/MM/YYYY"));
    // };

    onRowValidating = (e) => {
        if (!e) return false;
        const newData       = e.newData;
        const oldData       = e.oldData;
        const {brokenRules} = e;
        const ShiftRotationTypeID = typeof newData.ShiftRotationTypeID !== "undefined" ? newData.ShiftRotationTypeID : oldData.ShiftRotationTypeID;
        const ValidRotateDate = typeof newData.ValidRotateDate !== "undefined" ? newData.ValidRotateDate : oldData.ValidRotateDate;
        if(!ShiftRotationTypeID || !ValidRotateDate) {
            e.isValid = true;
            if (brokenRules && brokenRules.length > 0) {
                brokenRules.forEach(rule => {
                    rule.isValid = true;
                });
                e.component.refresh(true);
            }
        }

    };

    validateRequired = (key, e) => {
        const {data, value} = e;
        if (data.ShiftRotationTypeID) {
            if (key === "RotateOrder") {
                if (data.ValidRotateDate) {
                    return value === 0 || !!value;
                }
            } else {
                return value === 0 || !!value;
            }
        }
        return true;
    };

    onEditorPreparing = (e) => {
      if(e.dataField === "ValidRotateDate" && e.parentType === "dataRow") {
            e.editorOptions.onValueChanged = (data) => {
                if(this.dataGrid) {
                    if(this.rowIndex === 0 || !!this.rowIndex) {
                        this.dataGrid.instance.cellValue(this.rowIndex, 'RotateOrder', 0);
                        this.dataGrid.instance.cellValue(this.rowIndex, 'ValidRotateDate', data.value)
                    }
                }
            }
      }
    };

    render() {
        const { classes, getListOfShift, getListShiftRotation } = this.props;
        let { iPermission, ShiftID, dataGrid, gridLoading, isChecked, allowEditing, filterGridValue,
            isUpdated, isShiftRotation, dataShiftRotationTypeID, dataDropDown, isCancel, isColumnDefault } = this.state;
        if (!iPermission) return null;
        return (
            <React.Fragment>
                <div className={"hidden"}>{this.renderFilters()}</div>
                <FormGroup>
                        <ActionToolbar
                            title={Config.lang("DHR_Gan_ca_lam_viec")}
                        >
                                <div className={"display_row align-center align-between"} style={{width: '100%'}}>
                                    <div className={"display_row align-left"}>
                                        {allowEditing &&
                                        <ButtonGeneral name={Config.lang('DHR_Luu')}
                                                       disabled={gridLoading || !isUpdated || !(iPermission > 1)}
                                                       typeButton={"save"}
                                                       style={{textTransform: 'uppercase', marginRight: 5}}
                                                       size={"large"}
                                                       onClick={this.onSave}/>}
                                        {isCancel &&
                                        <ButtonGeneral name={Config.lang('DHR_Huy')}
                                                       disabled={gridLoading}
                                                       color={"default"}
                                                       variant="outlined"
                                                       style={{textTransform: 'uppercase', marginRight: 5}}
                                                       size={"large"}
                                                       onClick={this.onBack}/>}
                                        {!allowEditing &&
                                        <ButtonGeneral name={Config.lang('DHR_Gan_ca_mac_dinh')}
                                                       disabled={gridLoading || !(iPermission > 1)}
                                                       color={"primary"}
                                                       variant="contained"
                                                       style={{textTransform: 'uppercase', marginRight: 5}}
                                                       size={"large"}
                                                       onClick={() => this.onDisable('default')}/>}
                                        {!allowEditing &&
                                        <ButtonGeneral name={Config.lang('DHR_Gan_ca_xoay_vong')}
                                                       disabled={gridLoading || !(iPermission > 1)}
                                                       color={"primary"}
                                                       variant="contained"
                                                       style={{textTransform: 'uppercase'}}
                                                       size={"large"}
                                                       onClick={() => this.onDisable('Rotation')}/>}

                                    </div>

                                    <div className={"display_row align-center valign-bottom "}>
                                        {!isShiftRotation && <ButtonCustom
                                            name={Config.lang("DHR_Gan_ca")}
                                            color={"primary"}
                                            variant={"contained"}
                                            size={"medium"}
                                            className={"mgr10"}
                                            disabled={!ShiftID || !allowEditing}
                                            onClick={this.setShift}
                                        />}

                                        {isShiftRotation && <ButtonCustom
                                            name={Config.lang("DHR_Gan_ca_xoay_vong")}
                                            color={"primary"}
                                            variant={"contained"}
                                            size={"medium"}
                                            className={"mgr10"}
                                            disabled={!dataShiftRotationTypeID.ShiftRotationTypeID || !allowEditing}
                                            onClick={this.setShiftRotation}
                                        />}

                                        {!isShiftRotation && <Combo
                                            placeholder={Config.lang("DHR_Ca")}
                                            dataSource={getListOfShift}
                                            displayExpr={"ShiftName"}
                                            fieldRender={this.fieldRender}
                                            valueExpr={"ShiftID"}
                                            stylingMode={'underlined'}
                                            style={{width: "auto", margin: 0}}
                                            onValueChanged={e => this.filterChange('ShiftID', e)}
                                            itemRender={(e) => {
                                                if (!e.ShiftID) return null;
                                                return (
                                                    <div className={"display_row align-center"}
                                                         title={e.ShiftID + " - " + e.ShiftName}>
                                                        <span className={classes.boxShift}
                                                              style={{backgroundColor: this.getShiftColor(e)}}>{e.ShiftID}</span>
                                                        <span>{" " + e.TimeStart + " - " + e.TimeEnd}</span>
                                                    </div>
                                                );
                                            }}
                                            selectProps={{
                                                dropDownOptions: {
                                                    minWidth: 260
                                                }
                                            }}
                                            value={ShiftID}
                                        />}
                                        {isShiftRotation && <Combo
                                            placeholder={Config.lang("DHR_Ca_xoay_vong")}
                                            dataSource={getListShiftRotation}
                                            displayExpr={"ShiftRotationTypeName"}
                                            // fieldRender={this.fieldRender}
                                            // valueExpr={"ShiftRotationTypeID"}
                                            stylingMode={'underlined'}
                                            style={{width: "auto", margin: 0}}
                                            onValueChanged={e => this.filterChange('ShiftRotationTypeID', e)}
                                            itemRender={(e) => {
                                                if (!e.ShiftRotationTypeID) return null;
                                                return (
                                                    <div className={"display_row align-center"}
                                                         title={e.ShiftRotationTypeID + " - " + e.ShiftRotationTypeName}>
                                                        <span className={classes.boxTextShift}>{e.ShiftRotationTypeID}</span>
                                                        <span style={{width:120}}>{e.ShiftRotationTypeName}</span>
                                                    </div>
                                                );
                                            }}
                                            selectProps={{
                                                dropDownOptions: {
                                                    minWidth: 260
                                                }
                                            }}
                                            value={dataShiftRotationTypeID}
                                        />}
                                    </div>
                                </div>
                        </ActionToolbar>
                        <FormGroup>
                            <ActionToolbar className={"mgt0"} alignment={"flex-end"} allwaysTop={false}>
                                <div className={"display_row align-center align-between"} style={{width: '100%'}}>
                                    <Checkbox
                                        checked={isChecked}
                                        value={true}
                                        size={"medium"}
                                        label={Config.lang('DHR_Nhan_Vien_Chua_co_ca')}
                                        color={"primary"}
                                        onChange={(e) => this.filterChange('isChecked', e)}
                                    />
                                    <div/>
                                </div>
                            </ActionToolbar>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <GridContainer
                                        reference={ref => this.dataGrid = ref}
                                        style={{border: 'none', userSelect: "none"}}
                                        dataSource={dataGrid}
                                        height={Config.getHeightGrid() - 50}
                                        typePaging={"normal"}
                                        keyExpr={"EmployeeID"}
                                        showBorders={false}
                                        loading={gridLoading}
                                        filterRow={{
                                            visible: true,
                                            showOperationChooser: false,
                                        }}
                                        onContentReady={(e) => {
                                            this.setFocusFilter(500);
                                        }}
                                        filterPanel={{
                                            visible: true
                                        }}
                                        filterBuilderPopup={{
                                            onInitialized: (e) => {
                                                e.component.dispose();
                                                e.element.remove();
                                            }
                                        }}
                                        sorting={{
                                            mode: "none"
                                        }}
                                        filterValue={filterGridValue}
                                        editing={{
                                            mode: 'cell',
                                            refreshMode: 'full',
                                            // useIcons: true,
                                            allowUpdating: true,
                                            startEditAction: "dblClick"
                                        }}
                                        scrolling={{
                                            scrollByContent: false,
                                            scrollByThumb: true,
                                        }}
                                        onFilterGrid={(e) => {
                                            const {column, filterValue, columnIndex} = e;
                                            this.filterColIndx = columnIndex;
                                            if (column) {
                                                if (column.dataField === "EmployeeID") {
                                                    let dataFilter = [];
                                                    if (filterValue) {
                                                        if (filterValue.indexOf(" ") > -1 || filterValue.indexOf(";") > -1) {
                                                            const arrFilters = filterValue.indexOf(";") > -1 ? filterValue.split(";") : filterValue.split(" ");
                                                            arrFilters.forEach(f => {
                                                                dataFilter.push(["EmployeeID", "contains", f]);
                                                                dataFilter.push("or");
                                                            });
                                                            dataFilter.pop();
                                                        } else {
                                                            dataFilter = ["EmployeeID", "contains", filterValue];
                                                        }
                                                    }
                                                    this.filterGrid.EmployeeID = dataFilter;
                                                    this.setState({filterGridValue: this.getFilterGrid()});

                                                }  else if(column.dataField === "ValidDate") {
                                                    this.filterGrid.ValidDate = ["ValidDate", "=", filterValue ? filterValue : null ];
                                                    this.setState({filterGridValue: this.getFilterGrid()});
                                                }  else if(column.dataField === "ValidRotateDate") {
                                                    this.filterGrid.ValidRotateDate = ["ValidRotateDate", "=", filterValue ? filterValue : null ];
                                                    this.setState({filterGridValue: this.getFilterGrid()});
                                                } else if(column.dataField === "RotateOrder") {
                                                    this.filterGrid.RotateOrder = ["RotateOrder", "=", filterValue ? filterValue : null];
                                                    this.setState({filterGridValue: this.getFilterGrid()});
                                                } else {
                                                        this.filterGrid[column.dataField] = filterValue ? [column.dataField, "contains", filterValue] : [];
                                                        this.setState({filterGridValue: this.getFilterGrid()});
                                                }
                                            } else {
                                                this.filterGrid = {};
                                                this.setState({filterGridValue: []});
                                            }
                                        }}
                                        onEditingStart={this.onEditingStart}
                                        onKeyDown={this.onKeyDown}
                                        disabledCellSelection={!allowEditing}
                                        onCellSelectionChanged={(e) => {
                                            if (e.selectedRange && e.selectedRange.rowType !== "header") {
                                                this.selectedRange = e.selectedRange;
                                            }
                                        }}
                                        onRowValidating={this.onRowValidating}
                                        onEditorPreparing={this.onEditorPreparing}
                                        onRowUpdating={this.onRowUpdating}
                                        onRowUpdated={this.onRowUpdated}
                                    >
                                        <Column
                                            caption={Config.lang("DHR_Ma_nhan_vien")}
                                            dataField={'EmployeeID'}
                                            width={200}
                                            allowEditing={false}
                                            cellRender={this.renderEmployeeID}
                                        />
                                        <Column
                                            caption={Config.lang("DHR_Ten_nhan_vien")}
                                            dataField={'EmployeeName'}
                                            cellRender={this.renderNameEmployee}
                                            width={220}
                                            allowEditing={false}
                                        />
                                        <Column
                                            caption={Config.lang("DHR_Phong_ban")}
                                            dataField={'DepartmentName'}
                                            maxWidth={200}
                                            allowEditing={false}
                                        />
                                        <Column
                                            caption={Config.lang("DHR_Du_an")}
                                            dataField={'ProjectName'}
                                            maxWidth={200}
                                            allowEditing={false}
                                        />
                                        <Column
                                            caption={Config.lang("DHR_Ca_mac_dinh")}
                                            dataField={'DefaultShiftID'}
                                            alignment={"center"}
                                            visible={!isColumnDefault || !isShiftRotation}
                                            allowEditing={allowEditing}
                                            allowCopying={true}
                                            allowPasting={true}
                                            // allowFiltering={false}
                                            headerCellRender={this.renderHeaderColumns}
                                            cellRender={this.cellRender}
                                        />
                                        <Column
                                            caption={Config.lang("DHR_Mau_ca")}
                                            dataField={'ShiftRotationTypeID'}
                                            alignment={"center"}
                                            width={100}
                                            visible={!isColumnDefault || isShiftRotation}
                                            allowEditing={allowEditing}
                                            allowCopying={true}
                                            allowPasting={true}
                                            // allowFiltering={false}
                                            // headerCellRender={this.renderHeaderColumns}
                                            cellRender={this.cellRenderShiftRotation}
                                        />
                                        <Column
                                            caption={Config.lang("DHR_Ngay_di_ca")}
                                            dataField={'ValidRotateDate'}
                                            alignment={"center"}
                                            visible={!isColumnDefault || isShiftRotation}
                                            allowEditing={isShiftRotation}
                                            allowCopying={true}
                                            allowPasting={true}
                                            dataType={"date"}
                                            format={"dd/MM/yyyy"}
                                            // allowFiltering={false}
                                            // headerCellRender={this.renderHeaderColumns}
                                            // cellRender={this.cellRenderValidRotateDate}
                                        >
                                            {isShiftRotation &&  <CustomRule
                                                validationCallback={(e) => this.validateRequired("ValidRotateDate", e)}
                                                message="Giá trị bắt buộc nhập"/>}
                                        </Column>
                                        <Column
                                            caption={Config.lang("DHR_So_thu_tu")}
                                            dataField={'RotateOrder'}
                                            alignment={"center"}
                                            width={100}
                                            visible={!isColumnDefault || isShiftRotation}
                                            allowEditing={isShiftRotation}
                                            cellRender={this.cellRenderRotateOrder}
                                            editCellRender={(e) => {
                                                if(!e) return false;
                                                const { dataShiftRotationTypeID } = this.state;
                                                const {data} = e;
                                                let dataFilter = [];
                                                if (data.ShiftRotationTypeID) {
                                                    if (dataShiftRotationTypeID.RotateMode === 2) {
                                                        if (data.ValidRotateDate) {
                                                            const dayOfWeek = moment(data.ValidRotateDate).day() + 1;
                                                            dataFilter = dataDropDown.filter((value) => value.ShiftRotationTypeID === data.ShiftRotationTypeID && value.WeekDay === dayOfWeek);
                                                        }
                                                    } else {
                                                        dataFilter = dataDropDown.filter((value) => value.ShiftRotationTypeID === data.ShiftRotationTypeID);
                                                    }
                                                }
                                                return <Lookup
                                                    dataSource={dataFilter}
                                                    displayExpr={"RotateOrder"}
                                                    // valueExpr={"RotateOrder"}
                                                    defaultValue={data.RotateOrder}
                                                    dropDownOptions={{
                                                        height: "auto"
                                                    }}
                                                    onValueChanged={(lk) => {
                                                        if (lk) {
                                                            e.setValue(lk.value.RotateOrder);
                                                            e.component.cellValue(e.rowIndex, "DefaultShiftID", '');
                                                            e.component.cellValue(e.rowIndex, "ShiftName", lk.value.ShiftName);
                                                            e.component.cellValue(e.rowIndex, "ShiftID", lk.value.ShiftID);
                                                        }
                                                    }}
                                                />
                                            }}
                                        >
                                            {isShiftRotation &&  <CustomRule validationCallback={(e) => this.validateRequired("RotateOrder", e)}
                                                                             message="Giá trị bắt buộc nhập"/>}
                                        </Column>
                                        <Column
                                            dataField={'ShiftID'}
                                            alignment={"center"}
                                            visible={false}
                                            allowEditing={isShiftRotation}
                                        />
                                        <Column
                                            caption={Config.lang("DHR_Ca")}
                                            dataField={'ShiftName'}
                                            visible={!isColumnDefault || isShiftRotation}
                                            alignment={"center"}
                                            width={100}
                                            allowEditing={false}
                                        />
                                        <Column
                                            caption={Config.lang("DHR_Ngay_hieu_luc")}
                                            dataField={'ValidDate'}
                                            alignment={"center"}
                                            dataType={"date"}
                                            format={"dd/MM/yyyy"}
                                            allowEditing={allowEditing}
                                            allowCopying={true}
                                            allowPasting={true}
                                            headerCellRender={this.renderHeaderColumns}
                                        >
                                            <RequiredRule message="Giá trị bắt buộc nhập"/>
                                        </Column>
                                    </GridContainer>
                                </Col>
                            </Row>
                    </FormGroup>
                </FormGroup>
            </React.Fragment>
        )
    }
}

export default compose(connect(state => ({
    getCboProjects: state.general.getCboProjects,
    getListOfShift: state.W29F2025.getListOfShift,
    getListShiftRotation: state.W29F2025.getListShiftRotation,
}), (dispatch) => ({
    generalActions: bindActionCreators(generalActions, dispatch),
    w29F2025Actions: bindActionCreators(w29F2025Actions, dispatch)
})), withStyles(styles))(W29F2025);
