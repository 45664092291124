/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/19/2019
 * @Example
 */
import React from "react";
import { connect } from "react-redux";
import * as W29F2040Actions from "../../../../redux/W2X/W29F2040/W29F2040_actions";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import { browserHistory } from "react-router";
import { Column } from 'devextreme-react/data-grid';
import { Row, Col, FormGroup } from "react-bootstrap"
import GridContainer from "../../../grid-container/grid-container";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import ButtonGeneral from "../../../common/button/button-general";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import * as generalActions    from "../../../../redux/general/general_actions";
import _                      from 'lodash';
import moment                 from 'moment';
import { FormLabel as Label } from "@material-ui/core";
import { Combo }              from "../../../common/form-material";
import Filter                 from '../../../filter/filter';
import DateBoxPicker          from "../../../common/form-material/date-box";
import UserImage              from "../../../common/user/user-image";
import UserName               from "../../../common/user/user-name";
import Status                 from "../../../common/status/status";
import Icons                  from "../../../common/icons/";
import Popover                from "./W29F2040Popover";
import W29F2045               from "./../W29F2045/W29F2045"

const styles = {
    divAvatar: {
        width: 46,
        height: 46,
        minWidth: 46,
        minHeight: 46,
        borderRadius: '50%',
        overflow: 'hidden',
        marginRight: 8
    },
    imgAvatar: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    chip: {
        fontSize: '1rem',
        fontWeight: 400,
        border: 'none',
        backgroundColor: '#EBEBEB'
    },
    btnFilter: {
        backgroundColor: "#9999993d",
        textTransform: "uppercase"
    },
};
class W29F2040 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            mode: "add",
            DateFrom: null,
            DateTo: null,
            showW29F2045Popup: false,
            w29F2045PopupMode:"add",
            w29F2045PopupData:{},
            dataGrid: [],
            total: 0,
            edit: true,
            gridLoading: false,
            employeeLoading: false,
            orgChartsLoading: false,
            filterLoading: false,
            EmployeeID: null,

            open: false,
            type: "",
            dataPopover: null,
            anchorEl: null,
            OrgChartID:"",
        };
        this.filter = {
            EmployeeID: null,
            OrgChartID:null,
            ShiftID:null,
            skip: 0,
            limit: 10,
        };
        this.filterCboEmployees = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 20
        };
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W29F2040", (isPer) => {
            this.setState({ iPermission: isPer });
        });
    };

    loadCboEmployees = () => {
        const params = {
            HostID: "",
            Type: "EmployeeID",
            FormID: "W29F2040",
            Language: Config.language || '84',
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch
        };
        this.setState({ employeeLoading: true });
        this.props.generalActions.getCboEmployees(params, error => {
            this.setState({ employeeLoading: false });
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
        });
    };

    loadCboOrgCharts = () => {
        const param = {
            Language: Config.language || '84',
            FormID:"W29F2040",
            Mode:1,
        };
        this.setState({ orgChartsLoading: true });
        this.props.w29F2040Actions.getCboOrgCharts(param, (error) => {
            this.setState({ orgChartsLoading: false });
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
        });
    };
    loadCboShifts=()=>{
        this.props.w29F2040Actions.getCboShifts(error => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
        });
    }

;





    loadForms = () => {
        const {  skip, limit } = this.filter;
        const { DateFrom, DateTo, EmployeeID ,OrgChartID="",ShiftID=""} = this.state;
        let params = {
            Language: Config.language || "84",
            
            EmployeeID: EmployeeID && EmployeeID.EmployeeID ? EmployeeID.EmployeeID : "",
            ShiftID,
            OrgChartID,
            HostID: "",
            FormID: "W29F2040",
            skip,
            limit
        };
        if (DateFrom) {
            params.DateFrom= DateFrom
        }
        if (DateTo) {
            params.DateTo= DateTo
        }

        this.setState({ gridLoading: true });
        this.props.w29F2040Actions.getForms(params, (errors, data) => {
            if (errors) {
                let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({ gridLoading: false });
                return false;
            }
            let {rows=[],total=0}= data||{}
            rows= rows.map(item=>{

                return {
                    ...item,
                    employee:Config.getUser({EmployeeID:item.EmployeeID})
                }
            })
            this.setState({
                dataGrid:rows,
                total: total,
                
            }, () => {
                this.setState({ gridLoading: false });
            });
        });
    };

    getInfo = (flag) => {
        const { location } = this.props;
        if (!flag && location && location.state) {
            return {
                TransID: location.state.voucher_id,
            }
        } else {
            browserHistory.push(Config.getRootPath() + "W29F2040");
            return null;
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { location } = this.props;
        if (location && location.state && location.state.voucher_id) {
            this.checkNotify(location.state.voucher_id);
        }
    }

    async componentDidMount() {
        await this.loadPermission();
        if (!this.state.iPermission) return;
        this.loadForms();
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        const TransID1 = this.getInfo(nextProps).TransID;
        if (TransID1 && this.TransID !== TransID1) {
            this.TransID = TransID1;
            this.checkNotify(TransID1);
        }
    };

    checkNotify = (TransID) => {
        if (TransID) {
            this.onView({ row: { data: { TransID: TransID } } });
            browserHistory.push(Config.getRootPath() + 'W29F2040');
            this.TransID = null;
        }
    };

    getInfo = (props) => {
        const { location } = props ? props : {};
        const url = new window.URLSearchParams(window.location.search);
        if (url && url.get('voucher_id')) {
            return { TransID: url.get('voucher_id') };
        } else if (location && location.state) {
            return {
                TransID: location.state.voucher_id,
            }
        } else {
            return {};
        }
    };


    onLoadDataFilter = () => {
        this.loadCboEmployees();
        this.loadCboOrgCharts();
        this.loadCboShifts();
    };

    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadForms();
    };

    onChangePerPage = (perPage) => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadForms();
    };

    onAdd = () => {
        this.setState({
            w29F2045PopupMode: "add",
            w29F2045PopupData: null,
            showW29F2045Popup: true
        });
    };

    onCloseModal = (isSaved) => {
        if (isSaved) this.loadForms();
        this.setState({
            w29F2045PopupMode: "add",
            w29F2045PopupData: null,
            showW29F2045Popup: false,
        });
    };
    onView = (e) => {
        const { data } = e.row||e;
        let w29F2045PopupMode= data&&data.IsUpdate===0?"edit":"view"
        this.setState({
            w29F2045PopupMode,
            w29F2045PopupData: data,
            showW29F2045Popup: true
        });
    };

    onDelete = (e) => {
        const { data } = e.row||{};
        if (data && data.TransID) {
            Config.popup.show("YES_NO", Config.lang("DHR_Ban_co_chac_muon_xoa?"), () => {
                let params = {
                    FormID: "W29F2040",
                    TransID: _.get(data, "TransID", "")
                };
                this.props.w29F2040Actions.deleteActOTHour(params, (errors) => {
                    if (errors) {
                        let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        return false;
                    }
                    this.loadForms();
                    Config.notify.show('error', Config.lang("DHR_Xoa_thanh_cong"), 2000);
                });
            });
        } else {
            Config.popup.show('INFO', Config.lang("DHR_Loi_chua_xac_dinh"));
        }
    };

    onOpenAbort = (evt, e) => {
        const { data } = e.row||{};
        if(this.refPopover) {
            this.refPopover.onOpen(evt.currentTarget, data);
        }
    };

    filterChange = (key, data) => {
        if (!key) return false;
        let value = _.get(data, "value", "");
        value =value === null ? "" : value
        const stateFieldName = ['DateFrom', 'DateTo', 'EmployeeID','ShiftID',"OrgChartID"];
        if (stateFieldName.includes(key)) {
            
            this.setState({ [key]: value }) ;
            return;
        }
        this.filter[key] = value;

    };

    renderFilters = () => {
        const { EmployeeID, ShiftID, OrgChartID, DateFrom, DateTo,employeeLoading,orgChartsLoading } = this.state;
        const { getCboOrgCharts, getCboEmployees ,getCboShifts} = this.props;
        return (
            <Filter
                placeholder={Config.lang("DHR_Tim_kiem_ho_so_nhan_su")}
                onOpenLoaded={this.onLoadDataFilter}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup>
                                <Row>
                                    <Col xs={12}>
                                        <Label>{Config.lang("DHR_Ngay_de_xuat")}</Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={6}>
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5"}>{Config.lang("DHR_Tu")}</Label>
                                            <DateBoxPicker
                                                placeholder={moment().format("DD/MM/YYYY")}
                                                max={DateTo}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                width={"100%"}
                                                value={DateFrom}
                                                onValueChanged={e => this.filterChange("DateFrom", e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5"}>{Config.lang("DHR_Den")}</Label>
                                            <DateBoxPicker
                                                min={DateFrom}
                                                placeholder={moment().format("DD/MM/YYYY")}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                width={"100%"}
                                                value={DateTo}
                                                onValueChanged={e => this.filterChange("DateTo", e)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                
                               
                                
                                <Row>
                                    <Col xs={12} >
                                    <Combo
                                            dataSource={getCboOrgCharts}
                                            showClearButton={true}
                                            displayExpr={"OrgChartName"}
                                            keyExpr={'OrgChartID'}
                                            valueExpr={'OrgChartID'}
                                            value={OrgChartID }
                                            stylingMode={"outlined"}
                                            itemRender={data=>data && data.OrgChartName}
                                            label={Config.lang('DHR_Co_cau_to_chuc')}
                                            onValueChanged={e => this.filterChange("OrgChartID", e)}
                                            loading={orgChartsLoading}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} >
                                        <Combo.LoadMore
                                            dataSource={getCboEmployees}
                                            skip={this.filterCboEmployees.skip}
                                            limit={this.filterCboEmployees.limit}
                                            displayExpr={"EmployeeName"}
                                            keyExpr={'EmployeeID'}
                                            valueExpr={'EmployeeID'}
                                            value={EmployeeID}
                                            loading={employeeLoading}
                                            stylingMode={'outlined'}
                                            label={Config.lang('DHR_Nhan_vien')}
                                            itemRender={(e) => {
                                                const { data } = e;
                                                if (!data) return null;
                                                return data.EmployeeID + ' - ' + data.EmployeeName;
                                            }}
                                            showClearButton={true}
                                            onValueChanged={e => this.filterChange("EmployeeID", e)}
                                            onInputChanged={(e) => {
                                                if (this.filterCboEmployees.timer) clearTimeout(this.filterCboEmployees.timer);
                                                this.filterCboEmployees.strSearch = e.target.value;
                                                this.filterCboEmployees.timer = setTimeout(() => {
                                                    this.filterCboEmployees.skip = 0;
                                                    this.filterCboEmployees.limit = 20;
                                                    this.loadCboEmployees(true);
                                                }, 700);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboEmployees.skip = e.skip;
                                                this.filterCboEmployees.limit = e.limit;
                                                this.loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} >
                                        <Combo
                                            label={Config.lang("DHR_Ca")}
                                            dataSource={getCboShifts}
                                            displayExpr={"ShiftName"}
                                            valueExpr={"ShiftID"}
                                            onValueChanged={e => this.filterChange("ShiftID", e)}
                                            showClearButton={true}
                                            value={ShiftID || ""}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={"display_row align-center valign-middle"}>
                                <ButtonGeneral
                                    name={Config.lang("DHR_Tim_kiem")}
                                    typeButton={"search"}
                                    size={"large"}
                                    color={"primary"}
                                    variant={"outlined"}
                                    onClick={this.loadForms}
                                />
                            </FormGroup>
                        </>
                    );
                }}
            />
        );
    };

    renderEmployee = (e) => {
        const { data } = e.row;
      
        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <UserImage data={data}  />
                <div className={"w29f2040_info"}>
                    <div style={{ fontSize: '1.12rem' }}><UserName data={data} /></div>
                    <div >
                        {data.DepartmentName}
                    </div>
                    
                   
                </div>
            </div>
        );
    };
    renderDetail=e=>{
        const { data } = e.row;
        
        return  <div className={"display_row align-center pdt10 pdb10"}>
      
        <div className={"w29f2040_info"}>
           
            <div >
                <span style={{fontWeight:"bold" }}>{data.AttendanceDate&&`${Config.lang("DHR_Ngay")} ${moment(data.AttendanceDate).format("DD/MM/YYYY")}`}</span>,     
                <span>{data.ShiftName&&` ${Config.lang("DHR_Ca")} ${data.ShiftName}`}</span>    
                 
            </div>
            <div >
               
                <span>{data.AutualOT&&`${Config.lang("DHR_Bo_sung")}: ${data.AutualOT}`}</span>    
                 
            </div>
            <div >
            <span>{data.Note&&`${Config.lang("DHR_Ghi_chu")}: ${data.Note}`}</span>    
                 
            </div>
            
           
        </div>
    </div>
    }
    renderStatus = (e) => {
        const { data } = e.row;
        return (
            <Status data={data} />
        );
    };

    renderAction = (e) => {
        const { data } = e.row;

        const { iPermission } = this.state;
       
        return (
            <div className={"display_row align-center"}>
               
                <Divider orientation="vertical" style={{ width: 2, height: 11, margin: '0 5px' }} />
                <IconButton disabled={!(iPermission>0)} aria-label="view" size="small" onClick={() => this.onView(e)}>
                    <Icons name={"view"} />
                </IconButton>
                <Divider orientation="vertical" style={{ width: 2, height: 11, margin: '0 5px' }} />
                <IconButton disabled={data.IsUpdate ===1|| !(iPermission > 3)} aria-label="delete" size="small" onClick={() => this.onDelete(e)}>
                    <Icons colorHover="danger" name={"delete"} />
                </IconButton>
                
            </div>
        );
    };

    render() {
        let { iPermission, showW29F2045Popup,w29F2045PopupMode,w29F2045PopupData,  dataGrid, gridLoading, total,
            anchorEl, type, dataPopover
        } = this.state;
        if (!iPermission) return null;
        return (
            <React.Fragment>
               
                    <W29F2045 open={showW29F2045Popup}  mode={w29F2045PopupMode} data={w29F2045PopupData} FormID="W29F2040" onClose={this.onCloseModal} />
                
                <Popover
                    ref={ref => this.refPopover = ref}
                    anchorEl={anchorEl}
                    type={type}
                    data={dataPopover}
                    setLoading={isLoading => this.setState({gridLoading: isLoading})}
                    funcLoadGrid={this.loadForms}
                />
                <div className={"hidden"}>{this.renderFilters()}</div>
                <FormGroup>
                    <ActionToolbar
                        title={Config.lang("DHR_Bo_sung_gio_vao_ra_thuc_te")}
                    >
                        <ButtonGeneral name={Config.lang('DHR_Them')}
                            disabled={gridLoading || !(iPermission >= 2)}
                            typeButton={'add'}
                            style={{ textTransform: 'uppercase' }}
                            size={"large"}
                            onClick={this.onAdd} />
                    </ActionToolbar>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <GridContainer
                                style={{ border: 'none' }}
                                dataSource={dataGrid}
                                totalItems={total}
                                itemPerPage={this.filter.limit}
                                skipPerPage={this.filter.skip}
                                height={Config.getHeightGrid()}
                                typePaging={"remote"}
                                loading={gridLoading}
                                rowAlternationEnabled={false}
                                showRowLines={true}
                                showBorders={false}
                                showColumnLines={false}
                                showColumnHeaders={false}
                                noDataText={Config.lang("DHR_No_data")}
                                onChangePage={this.onChangePage}
                                onChangePerPage={this.onChangePerPage}
                                onDbRowClick={this.onView}
                                
                            >
                                <Column
                                    dataField={'employee'}
                                    cellRender={this.renderEmployee}
                              
                                />
                                <Column
                                    dataField={'detail'}
                                    cellRender={this.renderDetail}
                              
                                />
                               
                               
                                <Column
                                 
                                    cellRender={this.renderAction}
                                    width={150}
                                />
                            </GridContainer>
                        </Col>
                    </Row>
                </FormGroup>
            </React.Fragment>
        )
    }
}

export default compose(connect(state => ({
    
    getCboEmployees: state.general.getCboEmployees,
    getForms: state.W29F2040.getForms,
    getCboOrgCharts: state.W29F2040.getCboOrgCharts,
    getCboShifts: state.W29F2040.getCboShifts,
   
}), (dispatch) => ({
    generalActions: bindActionCreators(generalActions, dispatch),
    w29F2040Actions: bindActionCreators(W29F2040Actions, dispatch)
})), withStyles(styles))(W29F2040);
