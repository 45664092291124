/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 1/28/2021
 * @Example
 */
import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import Config from "../../../../config";
import * as W29F2050Actions from "../../../../redux/W2X/W29F2050/W29F2050_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import { bindActionCreators, compose } from "redux";
import moment from "moment";
import { Column } from "devextreme-react/data-grid";
import { makeStyles, Divider } from "@material-ui/core";
import { FormGroup, Row, Col } from "react-bootstrap";

import ActionToolbar from "../../../common/toolbar/action-toolbar";
import ButtonGeneral from "../../../common/button/button-general";
import GridContainer from "../../../grid-container/grid-container";
import Filter from "../../../filter/filter";
import DateBoxPicker from "../../../common/form-material/date-box";
import Icon from 'diginet-core-ui/icons';
import W29F2055Popup from "./W29F2055Popup";
import * as _        from "lodash";
import History from "../../../libs/history";
const useStyles = makeStyles(theme => ({
    divAvatar: {
        width: 46,
        height: 46,
        minWidth: 46,
        minHeight: 46,
        borderRadius: "50%",
        overflow: "hidden",
        marginRight: 8
    },
    imgAvatar: {
        maxWidth: "100%",
        objectFit: "contain"
    },
    dateInfo: {
        display: "flex",
        alignItems: "center"
    },
    divText: {
        // fontSize: "14px",
        fontWeight: 500,
        // color: "#575757"
    },
    divDateText: {
        // fontSize: "14px",
        fontWeight: 500,
        // color: "#000000"
    },
    styleDate: {
      fontWeight: 700,
      color: theme.palette.primary.main
    },

}));
const W29F2050 = (props) => {

    const _dataFilter = {
        strSearch: "",
        dateForm: null,
        dateTo: null,
        skip: 0,
        limit: 10
    };

    const _initDataPopup = {
        mode: "",
        rowData: null,
    };

    const [dataGrid,setDataGrid] = useState({rows: [], total: 0});
    const [showW29F2050Popup,setShowW29F2050Popup] = useState(false);
    const [dataFilter,setDataFilter] = useState(_dataFilter);
    const [loading,setLoading] = useState(false);
    const [iPermission, setIpermission] = useState(0);
    const [IsExport, setIsExport] = useState(0);
    const [dataPopup,setDataPopup] = useState(_initDataPopup);
    const classes = useStyles();

    const loadGrid = () => {
        const params = {
            DateFrom: dataFilter?.dateForm || null,
            DateTo: dataFilter?.dateTo || null,
            FormID: "W29F2050",
            skip: dataFilter.skip,
            limit: dataFilter.limit,
            Language: Config.language || 84,
            SearchValue: dataFilter.strSearch
        };
        if(params.DateFrom === null) {
            delete params.DateFrom;
        }
        if(params.DateTo === null) {
            delete params.DateTo;
        }
        setLoading(true);
        props.w29F2050Actions.getDataGrid(params, (error,data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            const rows = data && data.rows ? data.rows : data;
            const total = data && data.total ? data.total : data.length;
            setDataGrid({
                rows: rows,
                total: total
            });
            setLoading(false)
        });
    };


    useEffect(() => {
     const loadPermission = async () => {
            await props.generalActions.getPermission("W29F2050", isPer => {
                setIpermission(isPer);
            });
        };
     loadPermission();
     loadIsExport();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const loadIsExport = () => {
        props.w29F2050Actions.getIsExport((error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else {
                setIsExport(data?.IsExport || 0)
            }
        });
    };

    useEffect(() => {
        if(iPermission) {
            loadGrid();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[iPermission]);

    const setDataFilterGrid = (obj) => {
        setDataFilter({...dataFilter,...obj});
    };

    const onChangePage = page => {
        dataFilter.skip = page * dataFilter.limit;
        loadGrid();
    };

    const onChangePerPage = perPage => {
        dataFilter.skip = 0;
        dataFilter.limit = perPage;
        loadGrid();
    };


    const onSearch = () => {
        loadGrid();
    };

    const onCloseModal = (isReload = false) => {
        if(isReload) {
            loadGrid();
        }
        setShowW29F2050Popup(false)
    };

    const onAdd = () => {
        setDataPopup({
            mode: "add"
        });
        setShowW29F2050Popup(true)
    };

    const onEdit = e => {
        const { data } = e.row;
        setDataPopup({
            mode: "edit",
            rowData: data,
        });
        setShowW29F2050Popup(true)
    };

    const onView = e => {
        const { data } = e.row;
        setDataPopup({
            mode: "view",
            rowData: data,
        });
        setShowW29F2050Popup(true)
    };

    const onDelete = data => {
        const param = {
            FormID: "W29F2050",
            TransID: data.TransID
        };
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_xoa?"), () => {
            props.w29F2050Actions.deleteDataGrid(param, async error => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                } else {
                    Config.notify.show("success", Config.lang("Xoa_thanh_cong"), 3000);
                    await saveHistory(data);
                    loadGrid();
                }
            });
        });
    };

    const onExport = data => {
        const param = {
            TransID: data.TransID
        };
        props.w29F2050Actions.exportData(param, async (error, dataFile) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            else if(dataFile){
                const link = document.createElement("a");
                link.href = dataFile.URL ? dataFile.URL : "";
                link.download = dataFile.fileName ? dataFile.fileName : "";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        });
    };

    const saveHistory = async (data) => {
        if(!data) return false;
        const TransID = _.get(data, "TransID", "");
        if (TransID) {
            // data Content
            const _data = {
                AttendanceDate: _.get(data, "AttendanceDate", ""),
                ShiftName: _.get(data, "ShiftName", ""),
                ShiftID: _.get(data, "ShiftID", ""),
                OTHour: _.get(data, "OTHour", ""),
                ReasonOT: _.get(data, "ReasonOT", ""),
                Note: _.get(data, "Note", ""),
                OTTypeID: _.get(data, "OTTypeID", ""),
                IsConfirm01: Number(_.get(data, "IsConfirm01", 0)),
                IsConfirm02: Number(_.get(data, "IsConfirm02", 0)),
                IsConfirm03: Number(_.get(data, "IsConfirm03", 0)),
                IsConfirm04: Number( _.get(data, "IsConfirm04", 0)),
                IsConfirm05: Number(_.get(data, "IsConfirm05", 0)),
                IsOverTime: Number(_.get(data, "IsOverTime", 0)),
            };

            //name Content
            const captions = {
                AttendanceDate: "Ngay_cong",
                ShiftName: "Ca_vao_ra",
                ShiftID: "data_ShiftID",
                OTHour: "Gio_tang_ca",
                ReasonOT: "Ly_do_tang_ca",
                Note: "Ghi_chu",
                OTTypeID: "data_OTTypeID",
                IsConfirm01: "data_IsConfirm01",
                IsConfirm02: "data_IsConfirm02",
                IsConfirm03: "data_IsConfirm03",
                IsConfirm04: "data_IsConfirm04",
                IsConfirm05: "data_IsConfirm05",
                IsOverTime: "data_IsOverTime",
            };

            const options = {
                data: _data,
                captions: captions,
                action: 3,
                ModuleID: "D29",
                TransID: TransID,
                TransactionID: "W29F2050",
                TransactionName: "Lập lịch tăng ca",
            };
            const history = new History(options); //Init history
            if (await history.save()) {
                Config.notify.show("success", Config.lang("Luu_lich_su_thanh_cong"), 2000);
            } else {
                Config.notify.show("error", Config.lang("Luu_lich_su_khong_thanh_cong"), 2000);
            }
        } else {
            Config.notify.show("error", Config.lang("Luu_lich_su_khong_thanh_cong"), 2000);
        }

    };

    const filterChange = (key, e) => {
        if (!key) return false;
        switch (key) {
            case "strSearch":
                setDataFilterGrid({strSearch: e.target.value});
                break;
            case "DateFrom":
                setDataFilterGrid({dateForm: e.value});
                break;
            case "DateTo":
                setDataFilterGrid({dateTo: e.value});
                break;
            default:
                break;
        }
    };

    const renderDate = e => {
        if(!e) return null;
        const {data} = e || {};
        const date = (data.AttendanceDate && moment.utc(data.AttendanceDate).format("DD/MM/YYYY")) || null;
        return (
            <div className={"pdt10 pdb10 " + classes.divText}>
                {data.AttendanceDate && (
                    <div className={classes.divDateText}>
                        <span className={classes.styleDate}>{Config.lang("Ngay")}: {date || ""}</span>  {data.ShiftName || data.ShiftName}
                    </div>
                )}
                {data.OTHour && (
                    <div className={classes.divDateText}>
                        {Config.lang("Gio_tang_ca")}: {data.OTHour}
                    </div>
                )}
            </div>
        );
    };

    const renderInfo = e => {
        if(!e) return null;
        const {data} = e || {};
        return (
            <div className={"pdt10 pdb10 " + classes.divText}>
                {data?.VoucherCode && (
                    <div className={classes.divDateText}>
                        {Config.lang("Ma_phieu")}: {data.VoucherCode}
                    </div>
                )}
                {data.ReasonOT && (
                    <div className={classes.divDateText}>
                        {Config.lang("Ly_do_tang_ca")}:{data.ReasonOT || ""}
                    </div>
                )}
                {data.Note && (
                    <div className={classes.divDateText}>
                        {Config.lang("Ghi_chu")}:{data.Note || ""}
                    </div>
                )}
            </div>
        );
    };

    const renderAction = e => {
        if(!e) return null;
        const {data} = e || {};
        return (
            <div className={"display_row align-center"}>
                <Icon disabled={iPermission <= 0}
                      name={"View"}
                      onClick={() => onEdit(e)}
                />
                <Divider orientation="vertical" style={{ width: 2, height: 11, margin: "0 5px" }} />
                <Icon disabled={iPermission <= 3 || !!data.IsDelete}
                      name={"Trash"}
                       onClick={() => onDelete(data)}
                />
                <Divider orientation="vertical" style={{ width: 2, height: 11, margin: "0 5px" }} />
                <Icon disabled={!IsExport}
                      name={"Paper"}
                      onClick={() => onExport(data)}
                />
            </div>
        );
    };

    const renderFilter = () => {
        return (
            <Filter
                onTextChanged={e => filterChange("strSearch",e)}
                placeholder={Config.lang("Nhap_tu_tim_kiem")}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <div className={classes.divText}>{Config.lang("Tu")}</div>
                                            <DateBoxPicker
                                                dateBoxProps={{ max: dataFilter?.dateTo || null }}
                                                style={{ marginLeft: "5px" }}
                                                onValueChanged={e => filterChange("DateFrom", e)}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                value={dataFilter?.dateForm || null}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <div className={classes.divText}>{Config.lang("Den").toLowerCase()}</div>
                                            <DateBoxPicker
                                                dateBoxProps={{ min: dataFilter?.dateForm || null }}
                                                style={{ marginLeft: "5px" }}
                                                onValueChanged={e => filterChange("DateTo", e)}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                value={dataFilter?.dateTo || null}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={"display_row align-center valign-middle"}>
                                <ButtonGeneral name={Config.lang('Tim_kiem')}
                                               typeButton={'search'}
                                               color={"primary"}
                                               className={"pdl10 pdr10"}
                                               variant={"outlined"}
                                               style={{textTransform: 'uppercase'}}
                                               size={"large"}
                                               onClick={onSearch}/>
                            </FormGroup>
                        </>
                    );
                }}
            />
        );
    };

    return (
        <>
            <div className={"hidden"}>{renderFilter()}</div>
            <ActionToolbar title={Config.lang("Lap_lich_tang_ca")}>
                <ButtonGeneral
                    disabled={iPermission <= 1}
                    name={Config.lang("Them")}
                    typeButton={"add"}
                    style={{ textTransform: "uppercase" }}
                    size={"large"}
                    onClick={onAdd}
                />
            </ActionToolbar>
            { showW29F2050Popup &&
            <W29F2055Popup
                data={{
                    modeW29F2050: dataPopup.mode,
                    rowData: dataPopup.rowData
                }}
                iPermission={iPermission}
                open={showW29F2050Popup}
                onClose={(isReload) => onCloseModal(isReload)} />
            }
            <GridContainer
                style={{ border: "none" }}
                dataSource={dataGrid?.rows || []}
                totalItems={dataGrid?.total || 0}
                onDbCellClick={onView}
                showRowLines={true}
                showBorders={false}
                showColumnLines={false}
                showColumnHeaders={false}
                noDataText={Config.lang("No_data")}
                hoverStateEnabled={true}
                loading={loading}
                typePaging={"remote"}
                height={Config.getHeightGrid()}
                onChangePage={onChangePage}
                onChangePerPage={onChangePerPage}
            >
                <Column cellRender={renderDate} />
                <Column cellRender={renderInfo} />
                <Column cellRender={renderAction} width={200} />
            </GridContainer>
        </>
    );
};

export default compose(
    connect(null,
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            w29F2050Actions: bindActionCreators(W29F2050Actions, dispatch)
        })
    ))(W29F2050);
