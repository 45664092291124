/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 11/13/2020
 * @Example
 */
import React                                   from "react";
import {FormGroup, Col, Row, Image}            from "react-bootstrap";
import Config                                  from "../../../../config";
import ActionToolbar                           from "../../../common/toolbar/action-toolbar";
import Approvals                               from "../../../approvals/approvals";
import LabelText                               from "../../../common/label-text/label-text";
import {bindActionCreators, compose}           from "redux";
import {connect}                               from "react-redux";
import {FormLabel as Label, Input, withStyles} from "@material-ui/core";
import InlineSVG                               from "react-inlinesvg";
import * as ApprovalActions                    from "../../../../redux/approvals/approvals_actions";
import * as generalActions                     from "../../../../redux/general/general_actions";
import Filter                from "../../../filter/filter";
import {Combo, TextField}    from "../../../common/form-material";
import DateBoxPicker         from "../../../common/form-material/date-box";
import ButtonGeneral         from "../../../common/button/button-general";
import Typography            from "@material-ui/core/Typography";
import moment                from "moment";
const styles = {
    divAvatar: {
        width: 32,
        height: 32,
        borderRadius: '50%',
        border: '1px solid #ddd',
        overflow: 'hidden',
        marginRight: 8
    },
    imgAvatar: {
        width: '100%',
        maxWidth: '100%',
        objectFit: 'contain'
    },
    label: {
        fontSize: "1rem",
        fontWeight: 200,
        color:"#757575"
    },
    divText : {
        // fontSize: "1rem",
        fontWeight: 500,
        // color:"#575757"
    },
    expandPanel: {
        boxShadow: 'none'
    },
    expandSummary: {
        minHeight: '37px !important',
        padding: 0,
        '& .MuiExpansionPanelSummary-content': {
            margin: 0
        }
    },
    expandDetail: {
        display: 'block',
        padding: '10px 0'
    },
    hoverAction : {
        '& .MuiFormControl-root': {
            '& .MuiInputBase-root': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                // '&:hover': {
                background: "#FFAA00",
                '& .MuiButtonBase-root': {
                    position: 'absolute',
                    visibility: 'visible',
                    display: "inline"
                },
                // },
                '& .MuiInputBase-input': {
                    textAlign: "center",
                },
                // '& .MuiButtonBase-root': {
                //     visibility: 'hidden',
                //     display: "none"
                // }
            }
        }
    },
    disableInput : {
        '& .MuiFormControl-root': {
            '& .MuiInputBase-root': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: "#ECECEE",
                '& .MuiButtonBase-root': {
                    position: 'absolute',
                    display: "none"
                },
                '& .MuiInputBase-input': {
                    textAlign: "center",
                },
            }
        }
    },
    iconCenter: {
        '& .MuiFormControl-root': {
            '& .MuiInputBase-root': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& .MuiInputBase-input': {
                    textAlign: "center",
                }
            }
        }
    },
    styleIcon: {
        '& path': {
            fill: '#FFF'
        },
        "&:hover": {
            "& path": {
                fill: '#FFF'
            },
        }
    },
    styleShiftName: {
        fontSize:20,
        fontWeight:500,
        color: "#111D5E"
    }
};
const numberInputTime = 5;
class W29F2035 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            dataForm   : {},
            isExpanded : false,
            VoucherID  : "",
            AppStatusID: "",
            Employee: "",
            DateFrom: null,
            DateTo: null,
            dataCboEmployees: {
                rows: [],
                total: 0
            },
        };
        this.filterCboEmployees  = {
            strSearch: "",
            limit    : 50,
            skip     : 0
        };
        this.listNumberInputTime = [];
        for (let i = 1; i <= numberInputTime; i++) {
            this.listNumberInputTime.push(i);
        }
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W29F2035", (isPer) => {
            this.setState({iPermission: isPer});
        });
    };

    loadCboEmployees = (isReset) => {
        const param = {
            HostID: "",
            Type: "EmployeeID",
            FormID: "W29F2035",
            Language: Config.language || "84",
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch
        };
        this.setState({cboEmployeeLoading: true});
        this.props.generalActions.getCboEmployees(param, (error, data) => {
            this.setState({cboEmployeeLoading: false});
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                const {dataCboEmployees} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    loadCboAppStatus = () => {
        const param = {
            FormID: "W29F2035",
            Language: Config.language || 84
        };
        this.props.generalActions.getCboAppStatus(param, error => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
        });
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (!this.state.iPermission) return false;
        this.loadCboEmployees();
        this.loadCboAppStatus();
        this.checkNotify();
    };

    componentWillReceiveProps = () => {
        this.checkNotify();
    };

    checkNotify = () => {
        const {VoucherID} = this.getInfo();
        if(VoucherID && !this.voucher_id){
            this.voucher_id = VoucherID;
            this.loadFormInfo(VoucherID,(data)=>{
                this.voucher_id=null;
                data.VoucherID=VoucherID;
                this.refs['Approvals'].showPopup(data);
            });
        }
    };

    getInfo = () => {
        const {location} = this.props;
        const url = new window.URLSearchParams(window.location.search);
        if (url && url.get('voucher_id')) {
            return {VoucherID: url.get('voucher_id')};
        } else if (location && location.state) {
            return {
                VoucherID: location.state.voucher_id,
            }
        } else {
            return {};
        }
    };

    onSelectionChanged = (e) => {
        if (!e) return false;
        this.loadFormInfo(e.VoucherID);
    };

    onDrawerClosed = () => {
        this.setState({VoucherID: ""});
    };

    loadFormInfo = (VoucherID, cb) => {
        const params = {
            DivisionID: Config.getDivisionID(),
            FormID: "W29F2035",
            Language: Config.language || "84",
            VoucherID: VoucherID ? VoucherID : "",
        };
        this.setState({
            dataForm: {}
        });
        this.props.approvalActions.getFormInfo(params, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                this.setState({
                    dataForm: data
                });
                cb && cb (data);
            }
        });
    };

    renderFormInfo = (data) => {
        const {classes} = this.props;
        if (data && data.UserPictureURL && data.UserPictureURL.indexOf('http') < 0) {
            data.UserPictureURL = Config.getCDNPath() + data.UserPictureURL;
        }
        return (
            <>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Nhan_vien")}
                                value={() => {
                                    return (
                                        <div className={"display_row align-center pdb5"}
                                             style={{width: "100%", height: 37}}>
                                            <div className={classes.divAvatar}>
                                                {data && data.UserPictureURL ? (
                                                    <Image className={classes.imgAvatar} src={data.UserPictureURL}/>
                                                ) : (
                                                    <InlineSVG className={classes.imgAvatar}
                                                               src={require('../../../../assets/images/general/user_default.svg')}/>
                                                )}
                                            </div>
                                            <Input
                                                color={"primary"}
                                                readOnly={true}
                                                value={Config.helpers.getObjectValue(data, "EmployeeName", "") + " - " + Config.helpers.getObjectValue(data, "EmployeeID", "")}
                                                disableUnderline={true}
                                            />
                                        </div>
                                    );
                                }}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className={"mgb0"}>
                    <Row>
                        {data.AttendanceDate && (
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Typography style={{margin: "42px 0 12px 0"}} className={classes.styleShiftName}>
                                    {Config.lang("DHR_Ngay")} {moment(data.AttendanceDate).format("DD/MM/YYYY") || ""} {(data.AttendanceType && `- ${data.AttendanceType}`) || ""}
                                </Typography>
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Typography style={{marginBottom: 29}} className={classes.styleShiftName}> {data.ShiftName || ""}</Typography>
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                                    {Object.keys(data).length > 0 && this.listNumberInputTime && this.listNumberInputTime.map((num) => {
                                        if (data["TimeOn" + num] === "" || data["TimeOff" + num] === "") return null;
                                        return (
                                            <Row key={num}>
                                                <Col xs={12} sm={6} md={6} lg={6}>
                                                    <div
                                                        className={data["InUpdate" + num] ? classes.hoverAction : classes.disableInput}
                                                    >
                                                        <TextField variant={"outlined"}
                                                                   value={data["TimeOn" + num]}
                                                                   disabled={true}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col xs={12} sm={6} md={6} lg={6}>
                                                    <div
                                                        className={data["OutUpdate" + num] ? classes.hoverAction : classes.disableInput}
                                                    >
                                                        <TextField variant={"outlined"}
                                                                   value={data["TimeOff" + num]}
                                                                   disabled={true}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        );
                                    })}
                                </Col>
                    </Row>
                </FormGroup>
                <FormGroup style={{ marginBottom: "7px" }}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Ly_do")}
                                fullWidth={true}
                                value={Config.helpers.getObjectValue(data, "Reason", "")}
                            />
                        </Col>
                    </Row>
                </FormGroup>
            </>
        )
    };

    handleFilterChange = (key,e) => {
        if(!key) return false;
        const {value} = e;
        switch (key) {
            case "AppStatusID":
                this.setState({AppStatusID: value});
                break;
            case "EmployeeID":
                this.setState({Employee: value});
                break;
            case "DateFrom":
                this.setState({DateFrom: value});
                break;
            case "DateTo":
                this.setState({DateTo: value});
                break;
            default:
                break;

        }
    };

    loadGrid = () => {
        if(this.refs['Approvals']) this.refs['Approvals'].onReset(true);
    };

    filterRender = () => {
        const {getCboAppStatus} = this.props;
        const {dataCboEmployees, cboEmployeeLoading} = this.state;
        return (
            <Filter
                placeholder={Config.lang("DHR_Noi_dung_can_tim_kiem")}
                showHeader={false}
                openOnFieldClick={true}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Label className={"mgr5"}>{Config.lang("DHR_Trang_thai_duyet")}</Label>
                                        <Combo
                                            dataSource={getCboAppStatus}
                                            showClearButton={true}
                                            displayExpr={"AppStatusName"}
                                            valueExpr={"AppStatusID"}
                                            value={this.state.AppStatusID}
                                            stylingMode={"outlined"}
                                            placeholder={Config.lang("DHR_Chon")}
                                            onValueChanged={e => this.handleFilterChange("AppStatusID", e)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Label className={"mgr5"}>{Config.lang("DHR_Nhan_vien")}</Label>
                                        <Combo.LoadMore
                                            dataSource={dataCboEmployees}
                                            skip={this.filterCboEmployees.skip}
                                            limit={this.filterCboEmployees.limit}
                                            displayExpr={"EmployeeName"}
                                            keyExpr={'EmployeeID'}
                                            valueExpr={'EmployeeID'}
                                            value={this.state.Employee}
                                            loading={cboEmployeeLoading}
                                            stylingMode={'outlined'}
                                            placeholder={Config.lang('DHR_Chon')}
                                            itemRender={(e) => {
                                                const {data} = e;
                                                if (!data) return null;
                                                return data.EmployeeID + ' - ' + data.EmployeeName;
                                            }}
                                            showClearButton={true}
                                            // showCheckBoxMode={"none"}
                                            onValueChanged={(e) => this.handleFilterChange("EmployeeID", e)}
                                            onInputChanged={(e) => {
                                                this.filterCboEmployees.strSearch = e.target.value;
                                                this.filterCboEmployees.skip = 0;
                                                this.loadCboEmployees(true);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboEmployees.skip = e.skip;
                                                this.filterCboEmployees.limit = e.limit;
                                                this.loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Label className={"mgr5"} style={{margin: "16px 0 8px 0"}}>{Config.lang("DHR_Ngay_bo_sung")}</Label>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5"}>{Config.lang("DHR_Tu")}</Label>
                                            <DateBoxPicker
                                                max={this.state.DateTo}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                width={"100%"}
                                                placeholder={"DD/MM/YYYY"}
                                                value={this.state.DateFrom}
                                                onValueChanged={e => this.handleFilterChange("DateFrom", e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5"}>{Config.lang("DHR_Den")}</Label>
                                            <DateBoxPicker
                                                min={this.state.DateFrom}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                width={"100%"}
                                                value={this.state.DateTo}
                                                onValueChanged={e =>
                                                    this.handleFilterChange("DateTo", e)
                                                }
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={"display_row align-center valign-middle"}>
                                <ButtonGeneral name={Config.lang('DHR_Tim_kiem')}
                                               typeButton={'search'}
                                               color={"primary"}
                                               className={"pdl10 pdr10"}
                                               variant={"outlined"}
                                               style={{textTransform: 'uppercase'}}
                                               size={"large"}
                                               onClick={this.loadGrid}/>
                            </FormGroup>
                        </>
                    );
                }}
            />
        );
    };

    render() {
        const {iPermission, dataForm, VoucherID,Employee, DateFrom, DateTo, AppStatusID } = this.state;
        if (!iPermission) return null;
        const objSearch = {UserID: Config.profile.UserID ,ApprovalStatus : AppStatusID, DateFrom:
              DateFrom, DateTo: DateTo, EmployeeID: Employee?.EmployeeID || "",
              DepartmentID: "", ProjectID:"", DateFrom2: null, DateTo2: null, IsRegisterType : 0};
        return (
            <React.Fragment>
                <FormGroup>
                    <ActionToolbar
                        title={Config.lang("DHR_Duyet_bo_sung_thoi_gian_vao_ra")}
                    />
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Approvals
                                FormID={"W29F2035"}
                                dataForm={dataForm}
                                ref={'Approvals'}
                                selectedRowKey={VoucherID}
                                disabledApproval={iPermission < 2}
                                singleClick
                                dataFilter={objSearch}
                                filterRender={this.filterRender}
                                formInfoRender={this.renderFormInfo}
                                onSelectionChanged={this.onSelectionChanged}
                                onDrawerClosed={this.onDrawerClosed}
                            />
                        </Col>
                    </Row>
                </FormGroup>
            </React.Fragment>
        );
    }

}

export default compose(connect(state => ({
        getCboAppStatus: state.general.getCboAppStatus,
    }),
    (dispatch) => ({
        generalActions: bindActionCreators(generalActions, dispatch),
        approvalActions: bindActionCreators(ApprovalActions, dispatch),
    })
), withStyles(styles, {withTheme: true}))(W29F2035);
