/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 11/6/2020
 * @Example
 */
import React, { Component } from "react";

import { connect } from "react-redux";
import Config from "../../../../config";
import * as W29F2030Actions from "../../../../redux/W2X/W29F2030/W29F2030_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import { bindActionCreators, compose } from "redux";
import moment                                       from "moment";
import { browserHistory }                           from "react-router";
import { Column }                                   from "devextreme-react/data-grid";
import {withStyles, IconButton, FormLabel as Label} from "@material-ui/core";
import { Image, FormGroup, Row, Col }               from "react-bootstrap";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import ButtonGeneral from "../../../common/button/button-general";
import GridContainer from "../../../grid-container/grid-container";
import Filter from "../../../filter/filter";
import DateBoxPicker from "../../../common/form-material/date-box";
import { Combo } from "../../../common/form-material";
import W29F2030Popup from "../W29F2030/W29F2030Popup";
import Modal from "../../../common/modal/modal";
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";
import Status from "../../../common/status/status";
import Icons from "../../../common/icons/";

const styles = {
    divAvatar: {
        width: 46,
        height: 46,
        minWidth: 46,
        minHeight: 46,
        borderRadius: "50%",
        overflow: "hidden",
        marginRight: 8
    },
    imgAvatar: {
        maxWidth: "100%",
        objectFit: "contain"
    },
    dateInfo: {
        display: "flex",
        alignItems: "center"
    },
    divText: {
        // fontSize: "14px",
        fontWeight: 500,
        // color: "#575757"
    },
    divDateText: {
        // fontSize: "14px",
        fontWeight: 500,
        // color: "#000000"
    }
};

class W29F2030 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            mode: "",
            rowData: null,
            gridLoading: false,
            loading: false,
            showW29F2030Popup: false,
            AttendanceDate: null,
            dataGrid: [],
            total: 0,
            dataCboEmployees: {
                rows: [],
                total: 0
            },
            loadingCboEmployees: false,
        };
        this.filter = {
            Employee: "",
            AppStatusID: "",
            ShiftID: "",
            skip: 0,
            limit: 10
        };
        this.filterCboEmployees = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 50
        };
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W29F2030", isPer => {
            this.setState({ iPermission: isPer });
        });
    };

    loadDataGrid = () => {
        const {location} = this.props;
        const dataType = location && location.state && Object.entries(location.state).reduce((a, [key, value]) => {
            a[key.toLowerCase()] = value;
            return a;
        }, {});
        const type = dataType?.type || 0;
        const { Employee, AppStatusID, ShiftID, skip, limit } = this.filter;
        const { Datefrom, Dateto } = this.state;
        const param = {
            FormID: "W29F2030",
            Type: Number(type),
            Language: Config.language || 84,
            EmployeeID: Employee && Employee.EmployeeID ? Employee.EmployeeID : "",
            Datefrom: Datefrom || null,
            Dateto: Dateto || null,
            Status: AppStatusID || AppStatusID === 0 ? AppStatusID : "",
            ShiftID: ShiftID || "",
            skip: skip,
            limit: limit
        };
        this.setState({ gridLoading: true });
        this.props.w29f2030Actions.getDataGrid(param, (error,data) => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if(data) {
                this.setState({
                    dataGrid: data && data.rows ? data.rows : [],
                    total: data && data.total ? data.total : 0,
                }, () => {
                    this.setState({ gridLoading: false });
                });
            }

        });
    };

    loadCboEmployees = (isReset) => {
        const param = {
            Type: "EmployeeID",
            FormID: "W29F2030",
            Language: Config.language || "84",
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch
        };
        this.setState({loading: true});
        this.props.generalActions.getCboEmployees(param, (error, data) => {
            this.setState({loading: false});
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                const {dataCboEmployees} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    loadCboAppStatus = () => {
        const param = {
            FormID: "W29F2030",
            Language: Config.language || 84
        };
        this.setState({loading: true});
        this.props.generalActions.getCboAppStatus(param, error => {
            this.setState({loading: false});
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
        });
    };

     loadCboShift = () => {
         this.setState({loading: true});
        this.props.w29f2030Actions.getListOfShift(error => {
            this.setState({loading: false});
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
        });
        };

    onChangePage = page => {
        this.filter.skip = page * this.filter.limit;
        this.loadDataGrid();
    };

    onChangePerPage = perPage => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadDataGrid();
    };

    onChangePageProfile = data => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W09F2000",
            state: { EmployeeID: data.EmployeeID }
        });
    };

    onSearch = () => {
        this.loadDataGrid();
    };

    onCloseModal = (isSave = false) => {
        if(isSave) {
            this.loadDataGrid();
        }
        this.setState({
            showW29F2030Popup: false
        });
    };

    onView = e => {
        const { data } = e.row;
        this.setState({
            mode: "edit",
            rowData: data,
            showW29F2030Popup: true
        });
    };

    filterChange = (key, e) => {
        if (!key) return false;
        switch (key) {
            case "Datefrom":
                this.setState({
                    Datefrom: e.value
                });
                break;
            case "Dateto":
                this.setState({
                    Dateto: e.value
                });
                break;
            case "AppStatusID":
                this.filter.AppStatusID = e.value;
                break;
            case "EmployeeID":
                this.filter.Employee = e.value;
                break;
            case "ShiftID":
                this.filter.ShiftID = e.value;
                break;
            default:
                break;
        }
    };

    renderEmpProfile = e => {
        const { classes } = this.props;
        const {data = {}} = e;
        const date = moment.utc(data.CreateDate).format("LT, DD/MM/YYYY");
        if (data) {
            if (data.UserPictureURL) {
                data.UserPictureURL = data.UserPictureURL.indexOf('http') < 0 ? Config.getCDNPath() + data.UserPictureURL : data.UserPictureURL;
            } else if (data.EmployeeID) {
                const user = Config.getUser({EmployeeID: data.EmployeeID});
                data.UserPictureURL = user && user.UserPictureURL ? user.UserPictureURL : ""
            }
        }
        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <div className={classes.divAvatar}>
                    <UserImage data={data} onClick={() => this.onChangePageProfile(data)}/>
                </div>
                <div className={"w75f2000_info"}>
                    <div className={classes.divText}><UserName data={data}/></div>
                    <div className={classes.divText}>{data.DepartmentName || ""}</div>
                    <div className={classes.dateInfo}>
                        <Image src={require("../../../../assets/images/icon-calendar.svg")} />
                        <div style={{ paddingLeft: "8px" }} className={"date-text-info"}>
                            {date}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderInfo = e => {
        const { classes } = this.props;
        const {data = {}} = e;
        const date = moment.utc(data.AttendanceDate).format("DD/MM/YYYY");
        return (
            <div className={"pdt10 pdb10 " + classes.divText}>
                {data.AttendanceDate && (
                    <div className={classes.divDateText}>
                        {Config.lang("DHR_Ngay")}: {date || ""} {data.ShiftName || ""}
                    </div>
                )}
                {data.NoTimeOn && (
                    <div className={classes.divDateText}>
                         {data.NoTimeOn || ""}
                    </div>
                )}
                {data.NoTimeOff && (
                    <div className={classes.divDateText}>
                         {data.NoTimeOff || ""}
                    </div>
                )}
                {data.ApproverName && (
                    <div className={classes.divDateText}>
                        {Config.lang("DHR_Nguoi_duyet")}: {data.ApproverName || ""}
                    </div>
                )}
                {data.Notes && (
                    <div className={classes.divDateText}>
                        {Config.lang("DHR_Ghi_chu")}: {data.Notes || ""}
                    </div>
                )}
            </div>
        );
    };

    renderStatus = e => {
        const {data} = e;
        return <Status data={data}  keyExpr={"Status"} displayExpr={"StatusName"}/>;
    };

    renderAction = e => {
        const { iPermission } = this.state;
        return (
            <div className={"display_row align-center"}>
                <IconButton aria-label="view"
                            disabled={!iPermission > 0}
                            size="small" onClick={() => this.onView(e)}>
                    <Icons name={"view"}/>
                </IconButton>
            </div>
        );
    };

    renderFilter = () => {
        const { getCboAppStatus, getListOfShift } = this.props;
        const { AppStatusID, Employee, ShiftID,  } = this.filter;
        const { dataCboEmployees, loading, Datefrom, Dateto } = this.state;
        return (
            <Filter
                placeholder={Config.lang("DHR_Nhap_tu_tim_kiem")}
                openOnFieldClick={true}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Label className={"mgr5"}>{Config.lang("DHR_Tu")}</Label>
                                            <DateBoxPicker
                                                dateBoxProps={{ max:  Dateto }}
                                                style={{ marginLeft: "5px" }}
                                                onValueChanged={e => this.filterChange("Datefrom", e)}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                value={Datefrom || ""}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Label className={"mgr5"}>{Config.lang("DHR_Den")}</Label>
                                            <DateBoxPicker
                                                dateBoxProps={{ min: Datefrom }}
                                                style={{ marginLeft: "5px" }}
                                                onValueChanged={e => this.filterChange("Dateto", e)}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                value={Dateto || ""}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            label={Config.lang("DHR_Trang_thai_duyet")}
                                            dataSource={getCboAppStatus}
                                            displayExpr={"AppStatusName"}
                                            valueExpr={"AppStatusID"}
                                            onValueChanged={e => this.filterChange("AppStatusID", e)}
                                            showClearButton={true}
                                            value={AppStatusID === "%" ? "" : AppStatusID}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            label={Config.lang("DHR_Ca_lam_viec")}
                                            dataSource={getListOfShift}
                                            displayExpr={"ShiftName"}
                                            valueExpr={"ShiftID"}
                                            onValueChanged={e => this.filterChange("ShiftID", e)}
                                            showClearButton={true}
                                            value={ShiftID}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo.LoadMore
                                            dataSource={dataCboEmployees}
                                            skip={this.filterCboEmployees.skip}
                                            limit={this.filterCboEmployees.limit}
                                            displayExpr={"EmployeeName"}
                                            keyExpr={'EmployeeID'}
                                            valueExpr={'EmployeeID'}
                                            value={Employee === "%" ? null : Employee}
                                            stylingMode={'outlined'}
                                            loading={loading.cboEmployees}
                                            label={Config.lang('DHR_Nhan_vien')}
                                            itemRender={(e) => {
                                                const {data} = e;
                                                if (!data) return null;
                                                return data.EmployeeID + ' - ' + data.EmployeeName;
                                            }}
                                            showClearButton={true}
                                            // showCheckBoxMode={"none"}
                                            onValueChanged={e => this.filterChange("EmployeeID", e)}
                                            onInputChanged={(e) => {
                                                this.filterCboEmployees.strSearch = e.target.value;
                                                this.filterCboEmployees.skip = 0;
                                                this.loadCboEmployees(true);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboEmployees.skip = e.skip;
                                                this.filterCboEmployees.limit = e.limit;
                                                this.loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <ButtonGeneral
                                    name={Config.lang("DHR_Tim_kiem")}
                                    typeButton={"search"}
                                    size={"large"}
                                    color={"primary"}
                                    variant={"outlined"}
                                    onClick={this.onSearch}
                                />
                            </div>
                        </>
                    );
                }}
            />
        );
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (!this.state.iPermission) return;
        this.loadDataGrid();
        this.loadCboAppStatus();
        this.loadCboEmployees();
        this.loadCboShift();
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        const TransID1 = this.getInfo(nextProps).TransID;
        if (TransID1 && this.TransID !== TransID1) {
            this.TransID = TransID1;
            this.checkNotify(TransID1);
        }
    };

    checkNotify = (TransID) => {
        if (TransID) {
            this.onView({ row: { data: { TransID: TransID } } });
            browserHistory.push(Config.getRootPath() + 'W29F2030');
            this.TransID = null;
        }
    };

    getInfo = (props) => {
        if(!props) return {};
        const {location} = props ? props : {};
        const url = new window.URLSearchParams(window.location.search);
        if (url && url.get('voucher_id')) {
            return { TransID: url.get('voucher_id') };
        } else if (location && location.state) {
            return {
                TransID: location.state.voucher_id,
            }
        } else {
            return {};
        }
    };

    render() {
        const { gridLoading, iPermission, showW29F2030Popup, rowData, mode, dataGrid, dataCboEmployees, total } = this.state;
        const { skip, limit } = this.filter;
        if (iPermission <= 0) return null;

        return (
            <>
                <Modal
                    open={showW29F2030Popup}
                    maxWidth={"md"}
                    fullWidth={true}
                    onClose={() => this.onCloseModal(false)}
                    title={Config.lang("DHR_Bo_sung_gio_vao_ra")}
                >
                    <W29F2030Popup mode={mode} dataCboEmployee={dataCboEmployees} data={rowData} onClose={this.onCloseModal} />
                </Modal>
                <div className={"hidden"}>{this.renderFilter()}</div>
                <ActionToolbar title={Config.lang("DHR_Bo_sung_gio_vao_ra")}/>
                <GridContainer
                    style={{ border: "none" }}
                    dataSource={dataGrid}
                    totalItems={total}
                    showRowLines={true}
                    showBorders={false}
                    showColumnLines={false}
                    showColumnHeaders={false}
                    noDataText={Config.lang("DHR_No_data")}
                    hoverStateEnabled={true}
                    loading={gridLoading}
                    itemPerPage={limit}
                    skipPerPage={skip}
                    typePaging={"remote"}
                    height={Config.getHeightGrid()}
                    onChangePage={this.onChangePage}
                    onChangePerPage={this.onChangePerPage}
                >
                    <Column width={250} cellRender={this.renderEmpProfile} />
                    <Column cellRender={this.renderInfo} />
                    <Column cellRender={this.renderStatus} alignment={"center"} width={200} />
                    <Column cellRender={this.renderAction} width={200} />
                </GridContainer>
            </>
        );
    }
}

export default compose(
    connect(
        state => ({
            getCboAppStatus: state.general.getCboAppStatus,
            getListOfShift: state.W29F2030.getListOfShift,
        }),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            w29f2030Actions: bindActionCreators(W29F2030Actions, dispatch)
        })
    ),
    withStyles(styles)
)(W29F2030);
