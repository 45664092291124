/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 11/6/2020
 * @Example
 */
import React                         from "react";
import {connect}                     from "react-redux";
import PropTypes                     from "prop-types";
import {bindActionCreators, compose} from "redux";
import Config                        from "../../../../config";
import {Row, Col, FormGroup}  from "react-bootstrap";
import ButtonGeneral                 from "../../../common/button/button-general";
import withStyles                    from "@material-ui/core/styles/withStyles";
import * as generalActions           from "../../../../redux/general/general_actions";
import * as W29F2030Actions from "../../../../redux/W2X/W29F2030/W29F2030_actions";
import Attachments          from "../../../common/attachments/attachments";
import IconButton           from "@material-ui/core/IconButton";
import AttachFileIcon       from "@material-ui/icons/AttachFile";
import Modal                from "../../../common/modal/modal";
import CDN                  from "../../../CDN";
import {Typography, TextField as TextFieldM}         from "@material-ui/core";
import UserImage            from "../../../common/user/user-image";
import {Combo, MForm, TextField}       from "../../../common/form-material";
import moment               from "moment";
import {TextBox}            from "devextreme-react";
import InputMask            from 'react-input-mask'
import Icons                from "../../../common/icons";
const styles = {
    divAvatar: {
        width: 46,
        height: 46,
        minWidth: 46,
        minHeight: 46,
        borderRadius: "50%",
        overflow: "hidden",
        marginRight: 8
    },
    hoverAction : {
        '& .MuiFormControl-root': {
            '& .MuiInputBase-root': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                // '&:hover': {
                background: "#FFAA00",
                '& .MuiButtonBase-root': {
                    position: 'absolute',
                    visibility: 'visible',
                    display: "inline"
                },
                // },
                '& .MuiInputBase-input': {
                    textAlign: "center",
                },
                // '& .MuiButtonBase-root': {
                //     visibility: 'hidden',
                //     display: "none"
                // }
            }
        }
    },
    disableInput : {
        '& .MuiFormControl-root': {
            '& .MuiInputBase-root': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: "#ECECEE",
                '& .MuiButtonBase-root': {
                    position: 'absolute',
                    display: "none"
                },
                '& .MuiInputBase-input': {
                    textAlign: "center",
                },
            }
        }
    },
    iconCenter: {
        '& .MuiFormControl-root': {
            '& .MuiInputBase-root': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& .MuiInputBase-input': {
                    textAlign: "center",
                }
            }
        }
    },
    styleIcon: {
        '& path': {
            fill: '#FFF'
        },
        "&:hover": {
            "& path": {
                fill: '#FFF'
            },
        }
    },
    styleShiftName: {
        fontSize:20,
        fontWeight:500,
        color: "#111D5E"
    },
    hasDataTime : {
        '& .MuiFormControl-root': {
            '& .MuiInputBase-root': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                // '&:hover': {
                background: "#FFAA00",
                '& .MuiButtonBase-root': {
                    position: 'absolute',
                    visibility: 'visible',
                    display: "inline"
                },
                // },
                '& .MuiInputBase-input': {
                    textAlign: "center",
                },
                // '& .MuiButtonBase-root': {
                //     visibility: 'hidden',
                //     display: "none"
                // }
            }
        }
    },
    textError: {
        '& .MuiFormHelperText-root': {
            marginTop: 0,
            position: "relative",
            top: 0
        }
    }
};

const numberInputTime = 5;
class W29F2030Popup extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            error: {
                Reason: ""
            },
            loading: false,
            uploading: false,
            dataOldAttachments: [],
            dataForm: {
                EmployeeID: null,
                EmployeeName: "",
                ShiftID: "",
                AttendanceDate: null,
                AttendanceType: "",
                Notes: "",
                TimeOn1: "",
                InUpdate1: false,
                TimeOff1: "",
                OutUpdate1: false,
                TimeOn2: "",
                InUpdate2: false,
                TimeOff2: "",
                OutUpdate2: false,
                TimeOn3: "",
                InUpdate3: false,
                TimeOff3: "",
                OutUpdate3: false,
                TimeOn4: "",
                InUpdate4: false,
                TimeOff4: "",
                OutUpdate4: false,
                TimeOn5: "",
                InUpdate5: false,
                TimeOff5: "",
                OutUpdate5: false,
                Reason: "",
                Status: "",
            },
            iconState: {},
        };
        this.attachments = [];
        this.deletedFile = [];
        this.listNumberInputTime =[];
        for(let i = 1; i <= numberInputTime;i++) {
            this.listNumberInputTime.push(i);
        }
    }

    setStateErrorText = (value) => {
        const { error } = this.state;
        this.setState({
            error: {
                ...error,
                ...value
            }
        });
        return Object.keys(value).length !== 0;
    };

    _setDataForm = (key,value) => {
        if(!key) return false;
        this.setState({
            dataForm: {
                ...this.state.dataForm,
                [key]: value
            }
        });
    };

    handleChange = (key, e) => {
        if (!key || !e) return false;
        switch (key) {
            case "TimeOn1":
                this._setDataForm("TimeOn1",e.target.value);
                break;
            case "TimeOff1":
                this._setDataForm("TimeOff1",e.target.value);
                break;
             case "TimeOn2":
                this._setDataForm("TimeOn2",e.target.value);
                break;
            case "TimeOff2":
                this._setDataForm("TimeOff2",e.target.value);
                break;
             case "TimeOn3":
                this._setDataForm("TimeOn3",e.target.value);
                break;
            case "TimeOff3":
                this._setDataForm("TimeOff3",e.target.value);
                break;
            case "TimeOn4":
                this._setDataForm("TimeOn4",e.target.value);
                break;
            case "TimeOff4":
                this._setDataForm("TimeOff4",e.target.value);
                break;
            case "TimeOn5":
                this._setDataForm("TimeOn5",e.target.value);
                break;
            case "TimeOff5":
                this._setDataForm("TimeOff5",e.target.value);
                break;
            case "Reason":
                this._setDataForm("Reason",e.target.value);
                break;
            default:
                break;
        }
    };

    componentDidMount = () => {
        const {mode} = this.props;
        this.loadDataPopup();
        if (mode === 'edit') {
            this.loadAttachments();
        }
    };

    loadDataPopup = () => {
        const {data} = this.props;
        const param = {
            TransID: data && data.TransID ? data.TransID : "",
            FormID: "W29F2030",
            Language: Config.language || 84
        };
        this.setState({loading: true});
        this.props.w29f2030Actions.getDataFormPopup(param, (error, data) => {
            this.setState({loading: false});
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                this.setState({dataForm: {
                        ...this.state.dataForm,
                        EmployeeID: data.EmployeeID || null,
                        EmployeeName: data.EmployeeName || "",
                        ShiftID: data.ShiftID || "",
                        ShiftName: data.ShiftName || "",
                        AttendanceDate: data.AttendanceDate || null,
                        AttendanceType: data.AttendanceType || "",
                        Notes: data.Notes || "",
                        TimeOn1: data.TimeOn1 || "",
                        InUpdate1: data.InUpdate1 || false,
                        TimeOff1: data.TimeOff1 || "",
                        OutUpdate1: data.OutUpdate1 || false,
                        TimeOn2: data.TimeOn2 || "",
                        InUpdate2: data.InUpdate2 || false,
                        TimeOff2: data.TimeOff2 || "",
                        OutUpdate2: data.OutUpdate2 || false,
                        TimeOn3: data.TimeOn3 || "",
                        InUpdate3: data.InUpdate3 || false,
                        TimeOff3: data.TimeOff3 || "",
                        OutUpdate3: data.OutUpdate3 || false,
                        TimeOn4: data.TimeOn4 || "",
                        InUpdate4: data.InUpdate4 || false,
                        TimeOff4: data.TimeOff4 || "",
                        OutUpdate4: data.OutUpdate4 || false,
                        TimeOn5: data.TimeOn5 || "",
                        InUpdate5: data.InUpdate5 || false,
                        TimeOff5: data.TimeOff5 || "",
                        OutUpdate5: data.OutUpdate5 || false,
                        Reason: data.Reason || "",
                        Status: data.Status || "",
                    }});
            }
        });
    };


    onSave = () => {
        const {data} = this.props;
        const { dataForm } = this.state;
        //validate form...
        const validateRules = [
            {
                name: "Reason",
                rules: "isRequired",
                value: dataForm.Reason
            }
        ];
        const validateForm = MForm.formValidation(validateRules);
        if (Object.keys(validateForm).length !== 0) {
            this.setStateErrorText(validateForm);
            return false;
        } else {
            const arrAttachments = this._getAttachments();
            const params = {
                TimeOn1: dataForm?.TimeOn1 || null,
                TimeOff1: dataForm?.TimeOff1 || null,
                TimeOn2: dataForm?.TimeOn2 || null,
                TimeOff2: dataForm?.TimeOff2 || null,
                TimeOn3: dataForm?.TimeOn3 || null,
                TimeOff3: dataForm?.TimeOff3 || null,
                TimeOn4: dataForm?.TimeOn4 || null,
                TimeOff4: dataForm?.TimeOff4 || null,
                TimeOn5: dataForm?.TimeOn5 || null,
                TimeOff5: dataForm?.TimeOff5 || null,
                Reason: dataForm?.Reason || "",
                VoucherID: data?.TransID || "",
                Language: Config.language || "84",
                arrAttachment: JSON.stringify(arrAttachments)
            };
            this.setState({loading: true});
            this.props.w29f2030Actions.save(params, (error, data) => {
                this.setState({loading: false});
                if (error) {
                    let message = Config.lang("DHR_Loi_chua_xac_dinh");
                    Config.popup.show("INFO", message);
                    return false;
                }
                if (data) {
                    if (data.Status === 1) {
                        Config.popup.show('INFO', data.Message);
                        return false;
                    } else if (data.Status === 0) {
                        this._removeCDN();
                        this.onClose(true);
                        Config.notify.show('success', Config.lang("DHR_Luu_thanh_cong"), 2000);
                    } else {
                        Config.popup.show('INFO', Config.lang("DHR_Luu_khong_thanh_cong"));
                        return false;
                    }
                }
            });
        }
    };

    _removeCDN = () => {
        if (this.deletedFile && this.deletedFile.length > 0) {
            this.deletedFile.forEach((e) => {
                const path = e.URL.split("=");
                if (path && path.length > 1) {
                    const params = {
                        path: path[1]
                    };
                    CDN.removeFile(params);
                }
            });
            this.deletedFile = [];
        }
    };

    onClose = (isSave = false) => {
        const {onClose} = this.props;
        if (onClose) onClose(isSave);
    };

    onAttachment = () => {
        if (this.attRef) {
            this.attRef.onAttachment();
        }
    };

    _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(this.attachments);
        const {dataOldAttachments} = this.state;

        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileExt ? att.fileExt : ""
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    loadAttachments = () => {
        const {data} = this.props;
        const param = {
            KeyID: data && data.TransID ? data.TransID : "",
            TableName: "D29T3016"
        };
        this.props.generalActions.getAttachmentsByTransID(param, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data && data.length > 0) {
                this.setState({
                    dataOldAttachments: data
                });
            }
        });
    };

    onUploading = (value) => {
        this.setState({uploading: value});
    };


    onChangeAttachments = (e) => {
        const { disabled } = this.state;
        this.attachments = e.uploadedFiles ? e.uploadedFiles : [];
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            this.deletedFile = disabled ? [] : [ ...e.deletedFiles];
            const _arrRemove = this.deletedFile.map((d) => d.AttachmentID);
            this.setState(prevState => ({
                dataOldAttachments: prevState.dataOldAttachments.filter((att) => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                })
            }));
        }
    };

    renderFieldEmployee = (data) => {
        const {classes} = this.props;
        if (data) {
            return (
                <div className={"display_row align-right"}
                     style={{width: "100%", height: '100%'}}>
                    <UserImage data={data} width={32} height={32} />
                    <TextBox className={"cbo-employee-name " + classes.textBox}
                             value={`${data.EmployeeName || "" } - ${data.EmployeeID || ""}`}
                             readOnly={true}

                    />
                </div>
            );
        } else {
            return (
                <div className={"display_row align-right"} style={{width: "100%", height: '100%'}}>
                    <TextBox className={"cbo-employee-name " + classes.textBox}
                             defaultValue={""}
                             readOnly={true}

                    />
                </div>
            );
        }
    };

    onEdit = (nameField) => {
        this.setState( {iconState: {...this.state.iconState, [`isEdit${nameField}`] : true}});
    };

    render() {
        let {classes, dataCboEmployee
            } = this.props;
        const {uploading, error, loading, dataOldAttachments, disabled, dataForm,
              } = this.state;
        let checkStatus = (Number(dataForm.Status) === 0) || (Number(dataForm.Status) === 1);
        if (!checkStatus) {
            this.setState({disabled: true});
        }
        return (
            <>
                <Modal.Content>
                    <React.Fragment>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Combo
                                    dataSource={dataCboEmployee.rows}
                                    displayExpr={"EmployeeName"}
                                    valueExpr={'EmployeeID'}
                                    value={dataForm.EmployeeID}
                                    shrink={true}
                                    stylingMode={'underlined'}
                                    label={Config.lang("DHR_Nhan_vien")}
                                    fieldRender={() =>this.renderFieldEmployee(dataForm)}
                                    selectProps={{
                                        disabled: true
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            {dataForm.AttendanceDate && (
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Typography style={{margin: "42px 0 12px 0"}} className={classes.styleShiftName}>{Config.lang("DHR_Ngay")} {moment(dataForm.AttendanceDate).format("DD/MM/YYYY") || ""} {(dataForm.AttendanceType && `- ${dataForm.AttendanceType}`) || ""}</Typography>
                                </Col>
                            )}
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Typography style={{marginBottom: 29}} className={classes.styleShiftName}> {dataForm.ShiftName || ""}</Typography>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        {this.listNumberInputTime && this.listNumberInputTime.map(num => {
                                            const checkFormatChars = dataForm["TimeOn" + num].startsWith('2');
                                            return (
                                                <div key={num} className={ !disabled ?
                                                    !Config.isMobile && dataForm["InUpdate" + num] && dataForm["TimeOn" + num] !== "" && !this.state.iconState[`isEdit${`TimeOn${num}`}`]  ?  classes.hasDataTime :
                                                    !Config.isMobile && dataForm["InUpdate" + num] && !this.state.iconState[`isEdit${`TimeOn${num}`}`]  ? classes.hoverAction   :
                                                    !Config.isMobile && !dataForm["InUpdate" + num] ? classes.disableInput :
                                                    Config.isMobile && dataForm["InUpdate" + num] && !this.state.iconState[`isEdit${`TimeOn${num}`}`]  ? classes.hoverAction   :
                                                    Config.isMobile && !dataForm["InUpdate" + num] ? classes.disableInput :  classes.iconCenter : dataForm["InUpdate" + num] && dataForm["Status"] >= 2 ? classes.hasDataTime : classes.disableInput} >
                                                    {(dataForm["TimeOn" + num] !== "" || dataForm["TimeOff" + num] !== "") &&
                                                    (
                                                        <>
                                                            <InputMask
                                                                mask={'12:34'}
                                                                maskChar={(dataForm["TimeOn" + num] === "" && !this.state.iconState[`isEdit${`TimeOn${num}`}`]) ? "" :
                                                                    (disabled && !this.state.iconState[`isEdit${`TimeOn${num}`}`]) ? "" : "_"}
                                                                alwaysShowMask={true}
                                                                formatChars={{
                                                                    '1': '[0-2]',
                                                                    '2':  checkFormatChars ? '[0-3]' : '[0-9]',
                                                                    '3': '[0-5]',
                                                                    '4': '[0-9]',
                                                                }}
                                                                value={dataForm["TimeOn" + num] !== "" ? dataForm["TimeOn" + num] :
                                                                    (!dataForm["InUpdate" + num] ? dataForm["TimeOn" + num] :
                                                                    !this.state.iconState[`isEdit${`TimeOn${num}`}`] ? "" : dataForm["TimeOn" + num])}
                                                                onChange={(e) => this.handleChange("TimeOn" + num,e)}
                                                            >
                                                                {() => <TextFieldM  variant={"outlined"} InputProps={{
                                                                    readOnly: disabled || (dataForm["InUpdate" + num] ? !disabled ? false :
                                                                              !this.state.iconState[`isEdit${`TimeOn${num}`}`] : !dataForm["InUpdate" + num]),
                                                                    endAdornment: (
                                                                        !this.state.iconState[`isEdit${`TimeOn${num}`}`] && dataForm["TimeOn" + num] === "" &&
                                                                        <IconButton onClick={() =>this.onEdit("TimeOn" + num)}>
                                                                            <Icons className={classes.styleIcon} name={"edit"}/>
                                                                        </IconButton>
                                                                    )
                                                                }} />}
                                                            </InputMask>
                                                        </>
                                                    )
                                                    }
                                                </div>
                                            );
                                        })}
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        {this.listNumberInputTime && this.listNumberInputTime.map(num => {
                                            const checkFormatChars = dataForm["TimeOff" + num].startsWith('2');
                                            return (
                                                <div key={num} className={ !disabled ?
                                                !Config.isMobile && dataForm["OutUpdate" + num] && dataForm["TimeOff" + num] !== "" && !this.state.iconState[`isEdit${`TimeOff${num}`}`] ?  classes.hasDataTime :
                                                !Config.isMobile && dataForm["OutUpdate" + num] && !this.state.iconState[`isEdit${`TimeOff${num}`}`]  ? classes.hoverAction   :
                                                !Config.isMobile && !dataForm["OutUpdate" + num] ? classes.disableInput :
                                                 Config.isMobile && dataForm["OutUpdate" + num] && !this.state.iconState[`isEdit${`TimeOff${num}`}`]  ?  classes.hoverAction   :
                                                 Config.isMobile && !dataForm["OutUpdate" + num] ? classes.disableInput : classes.iconCenter :
                                                    dataForm["OutUpdate" + num] && dataForm["Status"] >= 2 ? classes.hasDataTime : classes.disableInput }
                                                >
                                                    {(dataForm["TimeOn" + num] !== "" || dataForm["TimeOff" + num] !== "") && (
                                                        <>
                                                            <InputMask
                                                                mask={'12:34'}
                                                                maskChar={(dataForm["TimeOff" + num] === "" && !this.state.iconState[`isEdit${`TimeOff${num}`}`]) ? "" :
                                                                          (disabled && !this.state.iconState[`isEdit${`TimeOff${num}`}`]) ? "" : "_"}
                                                                alwaysShowMask={true}
                                                                formatChars={{
                                                                    '1': '[0-2]',
                                                                    '2':  checkFormatChars ? '[0-3]' : '[0-9]',
                                                                    '3': '[0-5]',
                                                                    '4': '[0-9]',
                                                                }}
                                                                value={ dataForm["TimeOff" + num]!== "" ? dataForm["TimeOff" + num] :
                                                                      (!dataForm["OutUpdate" + num] ? dataForm["TimeOff" + num] : !this.state.iconState[`isEdit${`TimeOff${num}`}`] ? "" : dataForm["TimeOff" + num])}
                                                                onChange={(e) => this.handleChange("TimeOff" + num,e)}
                                                            >
                                                                {() => <TextFieldM variant={"outlined"} InputProps={{
                                                                    readOnly: disabled ||  (dataForm["OutUpdate" + num] ?  !disabled ? false :
                                                                        !this.state.iconState[`isEdit${`TimeOff${num}`}`] : !dataForm["OutUpdate" + num]),
                                                                    endAdornment: (
                                                                        !this.state.iconState[`isEdit${`TimeOff${num}`}`] && dataForm["TimeOff" + num] === "" &&
                                                                        <IconButton onClick={() =>this.onEdit("TimeOff" + num)}>
                                                                            <Icons className={classes.styleIcon} name={"edit"}/>
                                                                        </IconButton>
                                                                    )
                                                                }} />}
                                                            </InputMask>
                                                        </>
                                                    )}

                                                </div>
                                            );
                                        })}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    error={error && error["Reason"]}
                                    label={Config.lang("DHR_Ly_do")}
                                    variant={"standard"}
                                    margin={"normal"}
                                    className={classes.textError}
                                    value={dataForm.Reason}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    required={true}
                                    disabled={loading || disabled}
                                    onChange={e => this.handleChange("Reason", e)}
                                    fullWidth
                                />
                            </Col>
                        </Row>
                        <FormGroup style={{margin:"15px 0 34px 0"}}>
                            <Attachments
                                ref={ref => this.attRef = ref}
                                showButton={true}
                                files={dataOldAttachments}
                                disabled={loading || uploading ||  disabled}
                                maxLength={5}
                                uploading={loading}
                                onUploading={this.onUploading}
                                onChanged={this.onChangeAttachments}
                            />
                        </FormGroup>
                    </React.Fragment>
                </Modal.Content>
                <Modal.Actions style={{ justifyContent: "space-between" }}>
                    <div>
                        <IconButton aria-label="view" disabled={loading || uploading ||  disabled} onClick={this.onAttachment}>
                            <AttachFileIcon/>
                        </IconButton>
                    </div>
                    <div>
                        <ButtonGeneral
                            name={Config.lang("DHR_Luu")}
                            typeButton={"save"}
                            disabled={loading || uploading ||  disabled}
                            style={{textTransform: "uppercase"}}
                            size={"large"}
                            onClick={this.onSave}
                        />
                    </div>

                </Modal.Actions>
            </>
        );
    }
}

W29F2030Popup.propTypes = {
    open: PropTypes.bool,
    mode: PropTypes.string,
    data: PropTypes.any,
    onClose: PropTypes.func
};

export default compose(
    connect(
        state => ({
            getAttachmentsByTransID: state.general.getAttachmentsByTransID
        }),
        dispatch => ({
            w29f2030Actions: bindActionCreators(W29F2030Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch)
        })
    ),
    withStyles(styles)
)(W29F2030Popup);
