/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/19/2019
 * @Example
 */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as W29F2045Actions from "../../../../redux/W2X/W29F2045/W29F2045_actions";
import { bindActionCreators, compose }          from "redux";
import Config                                   from "../../../../config";
import { Row, Col, FormGroup }                  from "react-bootstrap";
import ButtonGeneral                            from "../../../common/button/button-general";
import withStyles                               from "@material-ui/core/styles/withStyles";
import { Combo }                     from "../../../common/form-material";
import * as generalActions                      from "../../../../redux/general/general_actions";
import {FormControl, Typography, Box, TextField as TextFieldM}           from "@material-ui/core";
import moment                                   from "moment";
import DateBoxPicker                            from "../../../common/form-material/date-box";
import Attachments                              from "../../../common/attachments/attachments";
import IconButton                               from "@material-ui/core/IconButton";
import AttachFileIcon                           from "@material-ui/icons/AttachFile";
import Modal                                    from "../../../common/modal/modal";
import Icons                                    from "../../../common/icons";
import { TextField } from "../../../common/form-material";
import InputMask  from "react-input-mask";
const styles = (theme)=>({
    avatar: {
        width: 30,
        height: 30,
        borderRadius: "50%",
        border: "1px solid #ddd",
        overflow: "hidden",
        marginRight: 8,
    },
    imgAvatar: {
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain",
    },
    chip: {
        fontSize: "1rem",
        fontWeight: 400,
        border: "none",
        backgroundColor: "#EBEBEB",
    },
    label: {
        fontSize: "1rem",
        fontWeight: 200,
        color: "#757575",
    },
    customHrTag: {
        // transform: "translateX(-5%)",
        width: "100%",
        // marginTop: "8px",
    },
  
    textError: {
        '& .MuiFormHelperText-root': {
            marginTop: 0,
            position: "relative",
            top: 0
        }
    },
    shiftNameBox:{
        marginTop:16
    },
    timeBox:{
        marginRight:16
    },
    p8:{
        marginTop:8
    },
    iconHoverRed:{
        "&:hover":{
            color:theme.palette.error.main
        }
    },
    styleTimePicker:{
        "& .MuiInputBase-input":{
            padding:"14px 14px",
            textAlign:"center",
            maxWidth:120
        }
        
    }
    
})
class W29F2045 extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            EmployeeID:"",
            Employee:"",
            Note:"",
            AttendanceTypeName:"",
            AttendanceType:"",
            AttendanceDate:null,
            IsUpdate1: 1,
            IsUpdate2: 0,
            IsUpdate3: 0,
            IsUpdate4: 0,
            IsUpdate5: 0,
            ShiftID: "",
            ShiftName: "",
            TimeOff1: "",
            TimeOff2: "",
            TimeOff3: "",
            TimeOff4: "",
            TimeOff5: "",
            TimeOn1: "",
            TimeOn2: "",
            TimeOn3: "",
            TimeOn4: "",
            TimeOn5: "",
            TransID: "",
            dataOldAttachments:[],
            error:{},
            dataCboEmployees:{},
            isReloadAfterClose:false,
            loadingFormsMaster:false,
           
            
        };
        this.filterCboEmployees = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 50,
        };
        
        this.attachments = [];
        this.deletedFile = [];
       
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W29F2040", (isPer) => {
            this.setState({ iPermission: isPer });
        });
    };

    loadCboEmployees = (isReset) => {
        const {FormID}= this.props
        const param = {
            HostID: "",
            Type: "EmployeeID",
            FormID,
            Language: Config.language || "84",
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch,
        };
        this.setState({ employeeLoading: true });
        this.props.generalActions.getCboEmployees(param, (error, data) => {
            this.setState({ employeeLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const { dataCboEmployees } = this.state;
                const _d = data.rows || [];
                const total = data.total || 0;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? _d : (dataCboEmployees.rows||[]).concat(_d),
                        total,
                    },
                });
            }
        });
    };
    loadAttendanceDateInfo =()=>{

    }
    loadAttachments = () => {
        const { data } = this.props;
       const {TransID} =data||{}
        if (!TransID) {
            Config.popup.show("INFO", Config.lang("DHR_KeyID_dang_rong_hoac_thieu_khong_the_tai_danh_sach_dinh_kem"));
            return false;
        }
        const params ={KeyID:TransID}
        this.props.generalActions.getAttachmentsByTransID(params, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data && data.length > 0) {
                this.setState({
                    dataOldAttachments: data,
                });
            }
        });
    };
     loadForms =()=>{
        const {TransID,EmployeeID,AttendanceDate,AttendanceType,WorkDayTypeName:AttendanceTypeName,ShiftID,ShiftInOut:ShiftName } = this.props.data||{}
        const {FormID,mode}= this.props
        const params ={FormID,Language:Config.language||84,TransID}
        if (FormID==="W29F2010"&&mode==="add") {
            let data ={EmployeeID,AttendanceDate:moment(AttendanceDate).format("YYYY-MM-DD"),AttendanceType,AttendanceTypeName,ShiftID,ShiftName}
            data.Employee=data.EmployeeID? Config.getUser({EmployeeID:data.EmployeeID}):null
            this.setState({...data})
            return
        }
        if (TransID) {
            this.props.w29F2045Actions.getForms(params,(error,data)=>{
                if(data){
                    for (let i = 1; i < 6; i++) {
                       if (data["TimeOn"+i] &&data["TimeOn"+i].length===4) {
                            data["TimeOn"+i]=data["TimeOn"+i].slice(0,2)+":" +data["TimeOn"+i].slice(2)
                       }
                       if (data["TimeOff"+i] &&data["TimeOff"+i].length===4) {
                            data["TimeOff"+i]=data["TimeOff"+i].slice(0,2)+":" +data["TimeOff"+i].slice(2)
                       }
                        
                    }
                    data.Employee=data.EmployeeID? Config.getUser({EmployeeID:data.EmployeeID}):null
                   this.setState({TransID,...data})
                }
            })
        }
        
       
    }
    loadFormsMaster =()=>{
        let {EmployeeID,AttendanceDate,error} = this.state
        let newError= {...error}
        newError.AttendanceDate=null
        
        if (AttendanceDate&&EmployeeID) {
            AttendanceDate = moment(AttendanceDate).format("YYYY-MM-DD")
            let timeData ={ShiftID:null,AttendanceType:null,ShiftName:null}
            for (let i = 1; i < 6; i++) {
                timeData["IsUpdate"+i]=0
                timeData["TimeOn"+i]=''
                timeData["TimeOff"+i]=''
                newError["IsUpdate"+i]=null
            }
            timeData["IsUpdate1"]=1
            this.setState({...timeData,error:newError,loadingFormsMaster:true})
            
            const params ={Language:Config.language||84,EmployeeID,AttendanceDate}
            this.props.w29F2045Actions.getFormsMaster(params,(error,data)=>{
                
                if(data&&data[0]){
                    this.setState({...data[0],loadingFormsMaster:false})
                }else{
                    this.setState({loadingFormsMaster:false,error:{...this.state.error,AttendanceDate:Config.lang("DHR_Ngay_bo_sung_gio_vao_ra_khong_co_ca_._Ban_vui_long_chon_ngay_khac")}})
            
                }
                
            })
        }
        
    }
    
    handleChange = async (key, e) => {
        if (!key) return false;
        switch (key) {
            case "EmployeeID":
                if (!e.value) return false;
                this.setState({ Employee: e.value,EmployeeID: e.value.EmployeeID },()=>this.loadFormsMaster());
                break;
            case "AttendanceDate":{
                if (!e.value) return false;
               
                this.setState({AttendanceDate:e.value },()=>this.loadFormsMaster());
                break;
            }
           
            default:
                this.setState({ [key]: e.value })
                break;
        }
    };

    async componentDidMount() {
        const { mode } = this.props;
        this.loadCboEmployees();
        
        if (mode !== "add") {
            
            this.loadForms();
            this.loadAttachments();
         
           
        }
    }
    componentDidUpdate(prevProps, prevState) {
       const {open,data,FormID,mode} = this.props 
        if (open&&open!==prevProps.open&&FormID!=="W29F2010" &&mode!=="view") {
            this.filterCboEmployees = {
                timer: null,
                strSearch: "",
                skip: 0,
                limit: 50,
            };
            this.loadCboEmployees(true)
        }
        if (data&&data!==prevProps.data &&data.TransID){
             this.loadForms()
             this.loadAttachments();
        }
    }


    
    onAttachment = () => {
        if (this.attRef) {
            this.attRef.onAttachment();
        }
    };

    onUploading = (value) => {
        this.setState({ uploading: value });
    };

    onChangeAttachments = (e) => {
        this.attachments = e.uploadedFiles ? e.uploadedFiles : [];
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            this.deletedFile = [...e.deletedFiles];
            const _arrRemove = this.deletedFile.map((d) => d.AttachmentID);
            this.setState((prevState) => ({
                dataOldAttachments: prevState.dataOldAttachments.filter((att) => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                }),
            }));
        }
    };
    _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(this.attachments);
        const { dataOldAttachments } = this.state;
        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileExt ? att.fileExt : "",
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    onSave = async (e,isContinue) => {
        const {EmployeeID,Note,AttendanceType,AttendanceDate,AttendanceTypeName,TransID,ShiftID,ShiftName}= this.state
        
        const {FormID,data}= this.props
        const {LinkOTTransID}= data||{}
        let params ={}
        const error={}
        //requied field
        let requiredFields= ["EmployeeID","AttendanceDate"]
        requiredFields.map(key=>{
            if ( !this.state[key]) {
                error[key]= Config.lang("DHR_Truong_nay_khong_hop_le")
            }
            return false
        })
        //handle time 
        let rgx = /^([0-1]?[0-9]|[2][0-3]):([0-5][0-9])$/
        const _formatTime =(match,h,m)=>{
            h= ("0"+h).slice(-2)
            m= ("0"+m).slice(-2)
            return h+m
        }
        // let lastOffTime="00:00"
        for (let i = 1; i < 6; i++) {
            if (this.state['IsUpdate'+i]===1) {
                //check
                let TimeOnI= this.state['TimeOn'+i]
                let TimeOffI= this.state['TimeOff'+i]
                // if (!TimeOnI ||!rgx.test(TimeOnI)) {
                //     error['IsUpdate'+i]=Config.lang("DHR_Truong_nay_khong_hop_le")
                //     continue
                // }
                // if (!TimeOffI ||!rgx.test(TimeOffI)) {
                //     error['IsUpdate'+i]=Config.lang("DHR_Truong_nay_khong_hop_le")
                //     continue
                // }
                // if (TimeOnI>=TimeOffI) {
                //     error['IsUpdate'+i]=Config.lang("DHR_Truong_nay_khong_hop_le")
                //     continue
                // }
                // if (TimeOnI<lastOffTime) {
                //     error['IsUpdate'+i]=Config.lang("DHR_Truong_nay_khong_hop_le")
                //     continue
                // }
                // lastOffTime=TimeOffI
                params['TimeOn'+i] = TimeOnI.replace(rgx,_formatTime)
                params['TimeOff'+i] = TimeOffI.replace(rgx,_formatTime)
                    
            }
            
        }
        //file
        const arrAttachments = this._getAttachments();
        //
        
        this.setState({error})    
        if (Object.keys(error).length) {
            return
        }
        //
        params = {
            ...params,
            EmployeeID,
            AttendanceType,
            AttendanceDate,
            arrAttachment:JSON.stringify(arrAttachments),
            LinkOTTransID,
            TransID,
            FormID:FormID==="W29F2040"?"W29F2040":'W29F2045',
            Note,
            AttendanceTypeName,ShiftID,ShiftName
        }
        this._onSave(params,isContinue)


    };

    _onSave = (params,isContinue) => {
        const {mode} = this.props
        this.setState({loading:true})
        if (mode==="add") {
            this.props.w29F2045Actions.saveForms(params,(error,data)=>{
                this.setState({loading:false})
                if (error) {
                    let message = Config.lang("DHR_Loi_chua_xac_dinh");
                    Config.popup.show("INFO",message)
                    return
                }
                if(data){
                    if(data.Status===0){
                        Config.notify.show("success", Config.lang("DHR_Luu_thanh_cong"), 2000);
                        if(isContinue){
                            this.onClearForm()
                            this.filterCboEmployees = {
                                timer: null,
                                strSearch: "",
                                skip: 0,
                                limit: 50,
                            };
                            this.loadCboEmployees(true)
                            
                            !this.state.isReloadAfterClose&&this.setState({isReloadAfterClose:true})
                        }else{
                            this.onClose(true)
                        }
                    }else{
                        Config.popup.show("INFO",data.Message||Config.lang("DHR_Da_co_loi_xay_ra_khong_the_luu_du_lieu"))
                    }
                }
                
            })
        }
        if (mode==="edit") {
            this.props.w29F2045Actions.editForms(params,(error,data)=>{
                this.setState({loading:false})
                if (error) {
                    let message = Config.lang("DHR_Loi_chua_xac_dinh");
                    Config.popup.show("INFO",message)
                    return
                }
                if(data){
                    if(data.Status===0){
                        Config.notify.show("success", Config.lang("DHR_Luu_thanh_cong"), 2000);
                        this.onClose(true)
                        
                    }else{
                        Config.popup.show("INFO",data.Message||Config.lang("DHR_Da_co_loi_xay_ra_khong_the_luu_du_lieu"))
                    }
                }
                
            })
        }
        
    }
    onClearForm =(cb)=>{
         //clear
         this.attachments = [];
         this.deletedFile = [];
         this.attRef && this.attRef.onReset()
         this.setState({
            EmployeeID:"",
            Employee:"",
            Note:"",
            AttendanceTypeName:"",
            AttendanceType:"",
            AttendanceDate:null,
            IsUpdate1: 1,
            IsUpdate2: 0,
            IsUpdate3: 0,
            IsUpdate4: 0,
            IsUpdate5: 0,
            ShiftID: "",
            ShiftName: "",
            TimeOff1: "",
            TimeOff2: "",
            TimeOff3: "",
            TimeOff4: "",
            TimeOff5: "",
            TimeOn1: "",
            TimeOn2: "",
            TimeOn3: "",
            TimeOn4: "",
            TimeOn5: "",
            TransID: "",
            error:{},
            dataOldAttachments:[],
            isReloadAfterClose:false
        },()=>cb&&cb())
        
    }
    onClose = (isSaved) => {
        const { onClose } = this.props;
        const {isReloadAfterClose}= this.state
        if (onClose){
           this.onClearForm(()=>onClose(isSaved||isReloadAfterClose))
            
        }
    };

    addNewTime =(e)=>{
        for (let i = 2; i < 6; i++) {
            if ((this.state['IsUpdate'+i] !==1 &&this.state['IsUpdate'+(i-1)] ===1)) {
                this.setState({['IsUpdate'+i]:1,['TimeOn'+i]:"",['TimeOff'+i]:""})
                return 
            }
        }
        
    }
    deleteTime= (e,i)=>{
        this.setState({['IsUpdate'+i]:0,['TimeOn'+i]:"",['TimeOff'+i]:""})
    }

    
    renderTime =(value,onChange)=>{
        const {classes,mode,loading} = this.props
     
        const disabled = mode==="view"
        return <Box className={classes.timeBox}>
        <InputMask
                        mask={'12:34'}
                        maskChar={"_"}
                        alwaysShowMask={true}
                        formatChars={{
                            '1': '[0-2]',
                            '2':  value&&value[0]==="2" ? '[0-3]' : '[0-9]',
                            '3': '[0-5]',
                            '4': '[0-9]',
                        }}
                        style={{marginTop:14}}
                       
                        disabled={loading || disabled}
                        value= {value}
                        onChange={e=>onChange(e.target)}
                    >
                        {(props) => <TextFieldM {...props}
                                            fullWidth={false}
                                               
                                            InputProps={{
                                                readOnly:disabled,
                                                className:classes.styleTimePicker
                                            }}
                                               margin={"normal"}
                                               required={true}
                                              
                                               variant="outlined" />}
                                               
                    </InputMask>
           
           
        </Box>
       
    }
    renderTimes= ()=>{
        const {classes,mode} = this.props
        const {error={}} = this.state
        const disabled = mode==="view"
        let result = []
        const _handleChange= (index,name,e)=>{
           
                this.handleChange(name+index,e)
            
        }
        for (let i = 1; i < 6; i++) {
            let TimeOn = this.state["TimeOn"+i]
            let TimeOff = this.state["TimeOff"+i]
            if (this.state['IsUpdate'+i] ===1) {
            result.push(<FormControl key={i} variant={"standard"}  margin={"normal"} fullWidth={true} style={{marginTop:15}}>

                        <div className={"display_row"}>
                            {this.renderTime(TimeOn||"",(value)=>_handleChange(i,"TimeOn",value))}
                            {this.renderTime(TimeOff||"",(value)=>_handleChange(i,"TimeOff",value))}
                            {
                                !disabled&&i!==1&&<Box className={classes.timeBox}>
                                                    <IconButton className={`${classes.p8}`} onClick={(e)=>this.deleteTime(e,i)}>
                                                        <Icons colorHover="danger" name={"delete"} />
                                                    </IconButton>
                                                </Box>
                            }
                            
                        </div>
                        {
                            error["IsUpdate"+i]&& <Typography color="error" variant="body2">{error["IsUpdate"+i]}</Typography>
                        }
                   
                    </FormControl>
)
          
                
            }
            
            
            
        }
        return result
    }
    render() {
        let { mode,open,classes,FormID,disabledCboEmployee} = this.props;
        let {
            loading,
            loadingFormsMaster,
            error,
            AttendanceTypeName,
            ShiftID,
            ShiftName,
            AttendanceDate,
            Note,
            Employee,
            dataOldAttachments,
            uploading,
            dataCboEmployees,
            employeeLoading, disabledSave,
            IsUpdate5,
            EmployeeID,
        } = this.state;
        let isAttendanceDateError
        const disabled = mode==="view"
        if (mode!=="view") {
            if (error.AttendanceDate) {
                isAttendanceDateError= error.AttendanceDate
            }else{
                isAttendanceDateError=!ShiftID?Config.lang("DHR_Ngay_bo_sung_gio_vao_ra_khong_co_ca_._Ban_vui_long_chon_ngay_khac"):false
            }
            
        }
        
        return (
            <Modal open={open} maxWidth={"md"} fullWidth={true}
                    title={Config.lang("DHR_Bo_sung_gio_vao_ra_thuc_te")}
                    onClose={()=>this.onClose()}>
                <Modal.Content>
                    
                    <div className={"row display_row align-center"}>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Combo.Employees
                                required={true}
                                acceptCustomValue={true}
                                error={error && error["EmployeeID"]}
                                dataSource={dataCboEmployees.rows}
                                totalItems={dataCboEmployees.total}
                                displayExpr={"EmployeeName"}
                                valueExpr={"EmployeeID"}
                                keyExpr={"EmployeeID"}
                                subTextExpr={"EmployeeID"}
                                stylingMode={"underlined"}
                                disabled={loading||disabled|| disabledCboEmployee|| mode=== "edit" }
                                skip={this.filterCboEmployees.skip}
                                limit={this.filterCboEmployees.limit}
                                
                                dropdownProps={{
                                    readOnly: disabled,
                                }}
                                loading={employeeLoading}
                                shrink={true}
                                value={Employee}
                                label={Config.lang("DHR_Nhan_vien")}
                                onValueChanged={(e) => this.handleChange("EmployeeID", e)}
                                onInputChanged={(e) => {
                                    this.filterCboEmployees.skip = 0;
                                    this.filterCboEmployees.strSearch = e.target.value;
                                    this.loadCboEmployees(true);
                                }}
                                onHoverAvatar={() => false}
                                onLoadMore={(e) => {
                                    this.filterCboEmployees.skip = e.skip;
                                    this.filterCboEmployees.limit = e.limit;
                                    this.loadCboEmployees();
                                }}
                            />
                        </Col>
                    
                    </div>
                   
                    <FormGroup style={{ marginBottom: 34 }}>
                        <Row className="display_row align-center">
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <DateBoxPicker
                                    
                                    label={Config.lang("DHR_Ngay")}
                                    stylingMode={"underlined"}
                                    margin={"normal"}
                                    shrink={true}
                                    
                                    width={"100%"}
                                    value={AttendanceDate ? moment(AttendanceDate).format("MM/DD/YYYY") : undefined}
                                    acceptCustomValue={true}
                                    disabled={loading||disabled|| FormID==="W29F2010"||mode==="edit"}
                                    required={true}
                                    dateBoxProps={{
                                        readOnly: disabled,
                                    }}
                                    onValueChanged={(e) => this.handleChange("AttendanceDate", e)}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6}>
                              <Box className={classes.shiftNameBox}>  <Typography>{AttendanceTypeName}</Typography></Box>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup style={{ marginBottom: 34,marginLeft:30 }}>
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                {
                                    EmployeeID&&AttendanceDate&&!loadingFormsMaster
                                    ?isAttendanceDateError
                                        ?<Typography color="error">{isAttendanceDateError}</Typography>
                                        :<> <Box className={`display_row align-center `} ><Typography variant="h6">{ShiftName}</Typography> 
                                            {
                                            !disabled&&<IconButton disabled={IsUpdate5===1}  onClick={this.addNewTime}>
                                                                <Icons colorHover="info" name={"add"} />
                                                            </IconButton>
                                            }
                                            </Box>
                                        
                                            {this.renderTimes()}
                                         </>
                                    :null
                                }
                               
                             </Col>
                           
                        </Row>
                    </FormGroup>
                    
                    <FormGroup style={{ marginBottom: 34 }}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                error={error && error["Note"]}
                                label={Config.lang("DHR_Ghi_chu")}
                                variant={"standard"}
                                margin={"normal"}
                                value={Note||""}
                                disabled={loading || disabled}
                                inputProps={{
                                    readOnly:disabled
                                }}
                                InputLabelProps={{
                                shrink: true
                                }}
                                helperText={this.state.helperTextQuantity}
                                onChange={e => this.handleChange("Note", e.target)}
                                fullWidth
                            />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup style={{ marginBottom: 34 }}>
                        <Attachments
                            error={error && error["Attachment"]}
                            ref={(ref) => (this.attRef = ref)}
                            showButton={true}
                            files={dataOldAttachments}
                            disabled={disabled||loading}
                            maxLength={5}
                            uploading={loading}
                            onUploading={this.onUploading}
                            onChanged={this.onChangeAttachments}
                        />
                    </FormGroup>
                </Modal.Content>
                <Modal.Actions style={{ justifyContent: "space-between" }}>
                    {!disabled &&  (
                        <>
                            <div>
                                <IconButton aria-label="view" disabled={loading} onClick={this.onAttachment}>
                                    <AttachFileIcon />
                                </IconButton>
                            </div>
                            <div>
                            {FormID!=="W29F2010"&&mode==="add"&&
                                <ButtonGeneral
                                name={Config.lang("DHR_Luu_&_nhap_tiep")}
                                typeButton={"save"}
                                disabled={
                                    (disabledSave || loading || uploading)   }
                                style={{ textTransform: "uppercase", marginRight:"10px" }}
                                size={"large"}
                                onClick={(e)=>this.onSave(e,mode==="add")}
                            />
                            }
                                <ButtonGeneral
                                    name={Config.lang("DHR_Luu")}
                                    typeButton={"save"}
                                    disabled={
                                        (disabledSave || loading || uploading)}
                                    style={{ textTransform: "uppercase" }}
                                    size={"large"}
                                    onClick={this.onSave}
                                />
                            </div>
                        </>
                    )}
                </Modal.Actions>
            </Modal>
        );
    }
}

W29F2045.propTypes = {
    open: PropTypes.bool,
    mode: PropTypes.string,
    data: PropTypes.any,
    onClose: PropTypes.func,
    FormID:PropTypes.string,
    disabledCboEmployee:PropTypes.bool,
};

export default compose(
    connect(
        (state) => ({
            getForms: state.W29F2045.getForms,
            getAttachments: state.W29F2045.getAttachments,
            
        }),
        (dispatch) => ({
            w29F2045Actions: bindActionCreators(W29F2045Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch),
        }),
    ),
    withStyles(styles,{withTheme:true})
)(W29F2045);
